<template>
  <div class="aLLdady">
    <div class="body">
      <div class="content">
        <div class="imgItem">
          <!-- <el-image  :src="item" lazy ></el-image> -->
          <!-- <img v-lazy="item" :key="item" alt  /> -->
          <div class="imgBody">
            <div
              class="el-imgItem"
              v-for="(item,k) in imgList"
              :key="k"
              v-loading="loading"
      
            >
              <el-image :src="item"></el-image>
            </div>
          </div>
          <!-- <img :src="item" alt loading="lazy" decoding="async" /> -->
          <div class="title-box">
            <div class="title">精选服务案例</div>
            <div class="ex-title">始终同步 更进一步</div>
          </div>

          <div class="moreCaseBody">
            <div class="moreCase">
              <div class="case" @click="ToCase(jumpToCase[0].id)">
                <img :src="jumpToCase[0].url" alt />
                <p class="border-font">{{jumpToCase[0].title}}</p>
                <p class="boder-content">{{jumpToCase[0].subTitle}}</p>
              </div>
              <div class="case" @click="ToCase(jumpToCase[1].id)">
                <img :src="jumpToCase[1].url" alt />
                <p class="border-font">{{jumpToCase[1].title}}</p>
                <p class="boder-content">{{jumpToCase[1].subTitle}}</p>
              </div>
            </div>
          </div>
          <div class="Learnmore" @click="jumpToSelectedcases">
            了解更多服务案例
            <img src="../assets/more.png" alt class="rightArrow" />
          </div>
        </div>
      </div>

      <!-- 原来是轮播图 现在不要了 -->
      <!-- <div class="swiperBox">
          <div class="swiper2-button-prev"></div>
          <div class="swiper-container swiper2">
            <div class="swiper-wrapper">
              <div class="swiper-slide slide2" v-for="(item,k) of jumpToCase" :key="k" @click="toCase(item.eName)">  
                <div class="inner" >
                  <div class="borderpic">
                    <img :src="require(`../assets/${item.imgSrc}.png`)" alt />
                  </div>
                  <div class="border-font">{{item.title}}</div>
                  <div class="boder-content">{{item.content}}</div>
                </div>
              </div>
             
            </div>
          </div>

          <div class="swiper2-button-next"></div>
      </div>-->
      <!-- 以上 -->
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
import smartZZ from "./SolutionChild/smartZZ.vue";
import smartZF from "./SolutionChild/smartZF.vue";
import smartYQ from "./SolutionChild/smartYQ.vue";
import smartYL from "./SolutionChild/smartYL.vue";
import smartWL from "./SolutionChild/smartWL.vue";
import smartWenLv from "./SolutionChild/smartWenLv.vue";
import smartSL from "./SolutionChild/smartSL.vue";
import smartSQ from "./SolutionChild/smartSQ.vue";
import smartNoY from "./SolutionChild/smartNoY.vue";
import smartNY from "./SolutionChild/smartNY.vue";
export default {
  components: {},

  methods: {
    ToCase(id) {
      this.$router.push({
        name: "case",
        params: {
          id
        }
      });
    },
    jumpToSelectedcases() {
      let hycj = document.getElementById("hycj");
      let jjfa = document.getElementById("jjfa");
      let jxal = document.getElementById("jxal");
      let fwnl = document.getElementById("fwnl");
      let gysd = document.getElementById("gysd");
      let jrwm = document.getElementById("jrwm");
      jjfa.style.fontFamily = "SC_Light";
      jxal.style.fontFamily = "SC_Bold";
      fwnl.style.fontFamily = "SC_Light";
      gysd.style.fontFamily = "SC_Light";
      jrwm.style.fontFamily = "SC_Light";
      hycj.style.fontFamily = "SC_Light";
      this.$router.replace("/Selectedcases");
    },
    toCase(eName) {
      this.$router.replace("/case");
    }
  },
  watch: {
    $route() {
      if (this.$route.params.id !== undefined) {
        let id = this.$route.params.id;
        this.id = id;
        this.imgList = [];
        this.loading = true;
        this.$axios(`getSolutionDetail?id=${id}`).then(res => {
          let data = JSON.parse(res.data.data);
          this.imgList = data;
          setTimeout(() => {
        this.loading = false;
      }, 500);
          console.log("imgList", res.data.data);
        });

        this.$axios.get("getSolutionByHomePage").then(res => {
          res.data.data.forEach(value => {
            const val = JSON.parse(value.subTitle);
            value.smallTitle = val.smallTitle;
            value.subTitle = val.subTitle;
          });

          this.jumpToSolution = res.data.data.filter(item => {
            return item.id !== this.id;
          });
          console.log(" this.jumpToSolution", this.jumpToSolution);
        });

        this.$axios.get(`getSolutionRecommended?id=${id}`).then(res => {
          console.log("res_最下面的两张图__", res.data.data);

          this.jumpToCase = res.data.data;
          console.log(" 啊?", this.jumpToCase);
        });
      }
    }
  },
  beforeCreate() {
    let id = this.$route.params.id;
    this.loading = true;
    //最下面的两张图
    // this.$axios.get("getCaseByHomePage").then(res => {
    this.$axios.get(`getSolutionRecommended?id=${id}`).then(res => {
      console.log("res_最下面的两张图__", res.data.data);

      this.jumpToCase = res.data.data;
      console.log(" 啊?", this.jumpToCase);
    });
    // let id = this.$route.params.id;

    this.$axios(`getSolutionDetail?id=${id}`).then(res => {
      let data = JSON.parse(res.data.data);
      this.imgList = data;
      setTimeout(() => {
        this.loading = false;
      }, 500);
    });

    this.$axios.get("getSolutionByHomePage").then(res => {
      res.data.data.forEach(value => {
        const val = JSON.parse(value.subTitle);
        value.smallTitle = val.smallTitle;
        value.subTitle = val.subTitle;
      });

      this.jumpToSolution = res.data.data;
      console.log(" this.jumpToSolution", this.jumpToSolution);
    });

    new Swiper(".swiper2", {
      slidesPerView: 2,

      freeMode: true,
      loop: true,
      navigation: {
        nextEl: ".swiper2-button-next",
        prevEl: ".swiper2-button-prev"
      }
    });

    this.$bus.$on("solution", item => {
      this.solutionData = item;
    });
    console.log(this.solutionData);
  },
  mounted() {},
  destroyed() {
    this.$bus.$off("solution");
  },
  data() {
    return {
      loading: true,
      jumpToCase: [],
      imgList: [],
      solutionData: "",
      jumpToSolution: [],
      id: 0
    };
  }
};
</script>
<style scoped>
.body {
  /* background-color: red; */
  height: 100%;
  padding-top: 55px;
}
/* .swiperBox{
  margin: 0 auto;
} */
/* .swiper-container{
  width: 40%;
} */

.swiper-container {
  width: 100vw;
  height: 1000px;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
}

.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 32px;
  left: 0;
  width: 100%;
}

/deep/.swiper-pagination-bullet {
  width: 6px !important;
  height: 6px !important;
  display: inline-block;
  border-radius: 100%;
  background: #999;
  opacity: 0.2;
}

/deep/.swiper-pagination-bullet-active {
  opacity: 1;
  background: #fff;
  border: 2px solid #999;
}

/deep/.swiper-container-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: 0 10px;
}

.Industryscenario {
  width: 100%;
  background-color: #ffff;
}

.IndustryscenarioTitle {
  overflow: hidden;
  text-align: center;
  line-height: 30px;
  letter-spacing: 3px;
  font-size: 30px;
  color: #333333;
  padding-top: 100px;
  padding-bottom: 80px;
}

.swiper2 {
  width: 55%;
  height: 450px;
  overflow: hidden;
}

.swiper-slide {
  /* width: 400px; */
  text-align: center;
  font-size: 13px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.text-group {
  position: absolute;
  left: 10%;
  bottom: 36%;
}
.text-group .title {
  font-family: "SC_Bold";
  font-size: 70px;
  margin: 0;
}
.text-group .content {
  font-family: "SC_Medium";
  font-size: 47px;
  margin: 0;
  margin-bottom: 50px;
}
.text-group .button {
  width: 100px;
  height: 30px;

  border-radius: 15px;
  margin-left: 20px;
}
.slide2 {
  overflow: hidden;
}

.inner {
  width: 100%;
  /* height: 100%; */
  color: #999;
}

.borderpic {
  width: 390px;
  height: 290px;
  margin: 0 auto;
  transition: 0.5s;
}

.borderpic img {
  width: 450px;
  height: 290px;
  /* border-radius: 50%; */
  margin: 0 auto;
  transition: 0.5s;
}
/* .inner:hover .borderpic {
  width: 230px;
  height: 230px;
}
.inner:hover .borderpic img {
  width: 230px;
  height: 230px;
} */

.border-font {
  width: 70%;
  text-align: center;
  /* line-height: 30px; */
  font-size: 16px;
  margin: 29px auto 0;
  font-family: "SC_Bold";
  color: #333333;
  margin-bottom: 5px;
}
.boder-content {
  text-align: center;
  font-size: 12px;
}
.swiper2-button-prev {
  outline: none;
  width: 43.7px;
  height: 45px;
  background: url("../assets/next.png") no-repeat;
  background-size: 100% 100%;
  top: 180px;
  left: 300px;
  position: relative;
}

.swiper2-button-next {
  outline: none;
  width: 43.7px;
  height: 45px;
  background: url("../assets/prev.png") no-repeat;
  border-radius: 50%;
  background-size: 100% 100%;
  top: 180px;
  right: 300px;
  position: relative;
}

.swiperBox {
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
}
</style>

<style scoped>
.Solution {
  width: 100%;
  background-color: #f7f7f7;
  border-bottom: 1px solid #f7f7f7;
  padding-bottom: 50px;
}

.SolutionTitle {
  width: 100%;
  overflow: hidden;
  text-align: center;
  line-height: 30px;
  letter-spacing: 3px;
  font-size: 30px;
  color: #333333;
  padding-top: 100px;
  padding-bottom: 80px;
}

/* .solutioncenter {
  width: 448px;
  height: 430px;
  background-color: #fff;
  margin-bottom: 80px;
} */

.solutionpic {
  width: 100%;
}

.solutionpic img {
  width: 100%;
  height: 100%;
  margin-bottom: 12px;
}

.solutionIntroduce {
  text-align: center;
  font-size: 20px;
  line-height: 20px;
  color: #333333;
  font-family: "SC_Medium";
  margin-bottom: 10px;
  margin-top: 10px;
}

.solutionName {
  text-align: center;
  font-size: 14px;
  color: #333333;
  line-height: 14px;
}

.selectedCases {
  width: 100%;
  overflow: hidden;
}

.selectedCasesBox {
  width: 1830px; /*1804px; */
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.selectedCasescenter {
  width: 601.333px;
  overflow: hidden;
  /* height: 9.375px; */
  background-color: #fff;
  /* margin-top: 20px; */
  /* min-height:7.375px;
  max-height: 9.375px; */
  transition: all 0.4s;
  margin-bottom: 40px;
  /* border: 2px solid black; */
  height: 450.333px;
}
.selectedCasescenter:hover {
  box-shadow: 0 8px 8px 0 #f2f2f2;
  /* transform: translate(0, -10px);  */
}
.selectedCasespic {
  /* height: 80%; */
  width: 100%;
  border-radius: 6px;
  overflow: hidden;
}

.selectedCasespic img {
  width: 100%;
  height: 100%;
}

.selectedCasesIntroduce {
  font-size: 20px;
  /* line-height: 20px; */
  color: #333333;
  font-family: "SC_Medium";
  margin-bottom: 10px;
  margin-top: 10px;
  margin-left: 20px;
}

.selectedCasesName {
  font-size: 14px;
  color: #333333;
  margin-left: 20px;
  line-height: 14px;
}

.Learnmore {
  text-align: center;
  font-size: 14px;
  color: #333333;
  letter-spacing: 2px;
  margin-top: 30px;
  margin-bottom: 100px;
  position: relative;
  padding-left: 900px;
  cursor: pointer;
  display: flex;
}
.Learnmore img {
  /* display: block;
  position: absolute;
  right: 30%; */
}
.FullService {
  width: 100%;
  height: 1000px;
  background-image: url(../assets/FullService.png);
  background-size: 100% 100%;
  color: #fff;
  text-align: center;
  background-repeat: no-repeat;
}

.FullServiceBox {
  position: relative;
  top: 45%;
  transform: translateY(-45%);
}

.FullServicefont1 {
  margin-bottom: 20px;
  font-size: 60px;
  letter-spacing: 1px;
  font-family: "SC_Bold";
}

.FullServicefont2 {
  margin-bottom: 5px;
  font-size: 36px;
  letter-spacing: 1px;
  color: #ffffff;
  line-height: 36px;
  margin-top: 20px;
}
.FullServicefont3 {
  margin-bottom: 5px;
  font-size: 46px;
  letter-spacing: 1px;
  line-height: 46px;
  margin-top: 20px;
  color: #424242;
}
.FullServiceBtn {
  width: 151.9px;
  height: 40.3px;
  font-size: 20px;
  background-color: #fff;
  color: #64619e;
  line-height: 40.3px;
  text-align: center;
  margin: 0 auto;
  border-radius: 20px;
  letter-spacing: 1px;
  margin-top: 60px;
  font-family: "SC_Regular";
  cursor: pointer;
}

.newIct {
  /* width: 100%; */
  height: 940px;
  position: relative;
  /* background-size: 100% 100%; */
  text-align: center;
  overflow: hidden;
  /* background-repeat: no-repeat; */
}
.bgimg {
  /* background-image: url(../assets/images/home/Image底.png); */
  /* background-repeat: no-repeat; */
  height: 100%;
  /* background-color: red; */
  position: relative;
}
.bgimg img {
  width: 100%;
  height: 100%;
}
.bgimg > .bgtext {
  height: 450px;
  width: 450px;
  /* background-color: red; */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-top: 101px;
}
.bgimg > .bgtext > .first {
  opacity: 1;
  color: #ffffff;
  font-size: 60px;
  margin: 0;
  margin-bottom: 5px;
  font-family: "SC_Medium";
}
.bgimg > .bgtext > .second {
  opacity: 1;
  color: #ffffff;
  font-size: 28px;
  margin: 0;
  margin-bottom: 76px;
  font-family: "SC_Medium";
}
.bgimg > .bgtext > .small {
  opacity: 1;
  color: #ffffff;
  font-size: 16px;
  line-height: 29px;
  margin: 0;
  font-family: "SC_Thin";
}
.bgimg > .bgtext > .bgbtn {
  /* background-color: red; */
  width: 100px;
  margin: 0 auto;
  margin-top: 20px;
}
.bgimg > .bgtext > .bgbtn img {
  width: 100%;
}
.cover {
  /* background-color: red */

  position: absolute;
  top: 0;
  left: 0;
  /* height: 100%; */
  width: 100%;
}
.cover-text {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.cover-text > .first {
  font-size: 70px;
  color: #333333;
  margin-bottom: 0px;
  font-family: "SC_Bold";
  opacity: 0;
}
.cover-text > .second {
  font-size: 46px;
  color: #333333;
  font-family: "SC_Medium";
  margin-top: 20px;
  opacity: 0;
}
.title-box {
  margin-top: 100px;
  margin-bottom: 30px;
}
.title-box .title,
.ex-title {
  margin: 0 auto;
  width: 500px;
  text-align: center;
  margin-left: 725px;
}
.title-box .title {
  color: #333333;
  font-family: "SC_Bold";
  margin-bottom: 10px;
  font-size: 30px;
}
.title-box .ex-title {
  color: #333333;
  font-family: "SC_Medium";
  font-size: 16px;
  margin-bottom: 60px;
}
.Learnmore {
  text-align: center;
  font-size: 14px;
  color: #333333;
  letter-spacing: 2px;
  margin-top: 30px;
  margin-bottom: 100px;
  position: relative;
}
.Learnmore .rightArrow {
  position: relative;
  /* display: block; */
  width: 27px;
  height: 17px;
  top: 4px;
}
.imgItem {
  /* position: relative; */
  /* height: 1500px; */
  /* background-color: red; */
  /* overflow: auto; */
  /* height: 700px;
  width: 100%; */
  /* height: 100vh; */
}
.imgBody {
  /* height: 100vh; */
  margin-top: 9px;
}
.imgItem::after {
  position: absolute;
  contain: "加载中";
  width: 100%;
  height: 100%;
}

.imgItem img {
  display: block;
  height: 100%;
  width: 100%;
}
.moreCase {
  padding-left: 19%;
  display: flex;
}
.moreCase .case {
  margin-left: 30px;
}
.moreCase img {
  /* width: 656px; */
  border-radius: 5px;
  display: block;
  height: 340px;
  width: 566px;
  /* padding-top: 60%; */
  /* margin-left: 30px; */
}
/* .el-image{
  height: 700px;

} */
.aLLdady {
  margin-bottom: 100px;
}
.el-imgItem {
  min-height: 500px;
}
.el-image {
  height: 100%;
}
</style>
