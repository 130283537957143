<template>
  <div class="body">
    <div class="newsbanner">
      <img src="../../assets/newsBanner.png" alt />
    </div>

    <div class="newlist">
      <div class="newListItem" v-for="(item,index) of newList" :key="index" @click.stop="toNewsDetail" :data-link="item.link" >
        <div class="content" :data-link="item.link">
        

          <div class="title" :data-link="item.link">{{item.title}}</div>
          <div class="desc" :data-link="item.link">{{item.briefIntroduction}} </div>

          <div class="date" :data-link="item.link">
           {{ item.createdTime }}
          </div>
        </div>
        <div class="image" :data-link="item.link">
          <img :src="item.bgUrl" alt  :data-link="item.link"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
       newList: []
    };
  },
  mounted () {
    this.$axios("news/list?count=1000").then(res => {
      console.log(res)
      res.data.data.forEach(item=>{
        item.date=item.createdTime.substring(5, 10);
        item.yaer=item.createdTime.substring(0, 4);
        item.date= item.date.replace("-","/")
        item.createdTime=item.createdTime.substring(0, 10);
      })
   
    
    
      console.log( res.data.data)
      this.newList=res.data.data
    })
  },
  methods: {
    toNewsDetail(e){
    console.log(e.target.dataset)
    window.open(e.target.dataset.link)
  }
  },

};
</script>

<style lang="css" scoped>
.body {
  /* height: 500px; */
  margin-top: 64px;
}
.newsbanner {
  width: 100vw;
  height: 620px;
  margin-top: 64px;
  margin-bottom: 80px;
}
.newsbanner img {
  width: 100%;
  height: 100%;
}
.newListItem {
  width: 1200px;
  height: 300px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #979797;;
  margin-bottom: 25px;
}
.newListItem:hover .desc {
  margin-left: 2px;
  /* border-left: 1px solid #e0e0e0; */
  padding-left: 30px;
  /* width: 100%; */
  word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  
}
.newListItem:hover .desc::after {
  opacity: 1;
  /* margin-left: 0px; */
  margin-left: -30px;
}
.content {
  /* width: 600px; */
  height: 252px;
  position: relative;
}
.date {
  /* display: flex; */
  margin-top: 41px;
  font-family: "SC_Medium";
text-align: right;
/* margin-top: 95px; */
width: 100px;
padding-left: 497px;
position: absolute;
bottom: 0;
font-size: 14px;
/* right: 0; */

}
.month {
  font-family: "SC_Medium";
  font-size: 26px;
  margin-right: 10px;
}
.year{
    font-family: "SC_Medium";   
    font-size: 16px;
    line-height: 40px;
}
.title {
  margin-top: 56px;
  overflow: hidden;
    text-overflow: ellipsis;
    white-space:nowrap;
  width: 600px;
  font-family: "SC_Medium";
  font-size: 22px;
}
.desc {
  width: 600px;
  margin-top: 24px;
  font-size: 14px;
  line-height: 21px;
  font-family: "SC_light";
  margin-left: 0px;
  transition: all 0.6s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  position: relative;
  word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.desc::after {
  content: "";
  display: block;
  height: 40px;
  width: 2px;
  background-color: #615fa2;
  position: absolute;
  top: 0px;
  /* left: -10px; */
  opacity: 0;
  
}
.image {
  width: 486px;
  height: 252px;
}
.image img {
  width: 100%;
  object-fit: cover;
  height: 100%;
}
</style>