<template>
  <div class="body">
    <div class="center">
      <div class="homeSwiper">
        <div class="swiper-container swiper1">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(item,k) in banner" :key="k" @click="gocase(item.id)">
              <img :src="item.videoUrl" alt />
             
              <div class="text-group">
                <p class="title">{{item.title}}</p>
                <p class="content">{{item.subTitle}}</p>
                <div class="button" @click="playvideo(item)" v-if="item.fileType=='video'">
                  <img src="../assets/watchVideo.png" alt />
                </div>
              </div>
            </div>
          </div>
          <!-- 如果需要分页器 -->
          <div class="swiper-pagination pagination1"></div>
        </div>
      </div>
      <div class="Selectedcasestitle">服务案例</div>
  
      <div class="selectedCasesBox">
        <div
          class="selectedCasescenter"
          @click="gocase(item.id)"
          v-for="(item,k) of jumpToCase"
          :key="k"
          :id="`item_block_${k+1}`"
        >
          <div class="selectedCasespic">
            <img :src="item.url" alt />
          </div>
          <div class="selectedCasesIntroduce">{{item.title}}</div>
          <div class="selectedCasesName">{{item.subTitle}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
import { gsap } from "gsap";
export default {
  data() {
    return {
      jumpToCase: [],
      banner:[]
    };
  },
  mounted() {

    this.$axios("getCaseBanner").then(res => {
      console.log('???')
      this.banner = res.data.data;
      this.$nextTick(() => {
        new Swiper(".swiper1", {
          loop: false, // 循环模式选项
          // 如果需要分页器 
          pagination: {
            el: ".pagination1",
            clickable: true
          },
          observer: true,
          observeParents: true
          // 如果需要滚动条
        });
      });
    });

    this.$axios.get("getCaseAll").then(res => {
      console.log(res);
      this.jumpToCase = res.data.data;
      console.log("123213123123123123", this.jumpToCase);
      this.$nextTick(() => {
        gsap.from("#item_block_1", {
          scrollTrigger: "#item_block_1",
          duration: 2,
          opacity: 0,
          y: 200
        });
        gsap.from("#item_block_2", {
          scrollTrigger: "#item_block_2",
          duration: 1,
          opacity: 0,
          y: 200
        });
        gsap.from("#item_block_3", {
          scrollTrigger: "#item_block_3",
          duration: 2.2,
          opacity: 0,
          y: 200
        });
        gsap.from("#item_block_4", {
          scrollTrigger: "#item_block_4",
          duration: 1,
          opacity: 0,
          y: 200
        });

        gsap.from("#item_block_5", {
          scrollTrigger: "#item_block_5",
          duration: 2,
          opacity: 0,
          y: 200
        });
        gsap.from("#item_block_6", {
          scrollTrigger: "#item_block_6",
          duration: 1,
          opacity: 0,
          y: 200
        });
        gsap.from("#item_block_7", {
          scrollTrigger: "#item_block_7",
          duration: 2.2,
          opacity: 0,
          y: 200
        });
        gsap.from("#item_block_8", {
          scrollTrigger: "#item_block_8",
          duration: 1,
          opacity: 0,
          y: 200
        });
        gsap.from("#item_block_9", {
          scrollTrigger: "#item_block_9",
          duration: 2,
          opacity: 0,
          y: 200
        });
        gsap.from("#item_block_10", {
          scrollTrigger: "#item_block_10",
          duration: 1,
          opacity: 0,
          y: 200
        });
        gsap.from("#item_block_11", {
          scrollTrigger: "#item_block_11",
          duration: 2.2,
          opacity: 0,
          y: 200
        });
        gsap.from("#item_block_12", {
          scrollTrigger: "#item_block_12",
          duration: 1,
          opacity: 0,
          y: 200
        });
        gsap.from("#item_block_13", {
          scrollTrigger: "#item_block_13",
          duration: 1,
          opacity: 0,
          y: 200
        });
        gsap.from("#item_block_14", {
          scrollTrigger: "#item_block_14",
          duration: 2,
          opacity: 0,
          y: 200
        });
        gsap.from("#item_block_15", {
          scrollTrigger: "#item_block_15",
          duration: 1,
          opacity: 0,
          y: 200
        });
        gsap.from("#item_block_16", {
          scrollTrigger: "#item_block_16",
          duration: 2.2,
          opacity: 0,
          y: 200
        });
        gsap.from("#item_block_17", {
          scrollTrigger: "#item_block_17",
          duration: 2,
          opacity: 0,
          y: 200
        });
        gsap.from("#item_block_18", {
          scrollTrigger: "#item_block_18",
          duration: 1,
          opacity: 0,
          y: 200
        });
        gsap.from("#item_block_19", {
          scrollTrigger: "#item_block_19",
          duration: 2,
          opacity: 0,
          y: 200
        });
        gsap.from("#item_block_20", {
          scrollTrigger: "#item_block_20",
          duration: 2.2,
          opacity: 0,
          y: 200
        });
        gsap.from("#item_block_21", {
          scrollTrigger: "#item_block_21",
          duration: 1,
          opacity: 0,
          y: 200
        });

        gsap.from("#item_block_22", {
          scrollTrigger: "#item_block_22",
          duration: 2,
          opacity: 0,
          y: 200
        });
        gsap.from("#item_block_23", {
          scrollTrigger: "#item_block_23",
          duration: 1,
          opacity: 0,
          y: 200
        });
        gsap.from("#item_block_24", {
          scrollTrigger: "#item_block_24",
          duration: 1,
          opacity: 0,
          y: 200
        });
        gsap.from("#item_block_25", {
          scrollTrigger: "#item_block_25",
          duration: 2.2,
          opacity: 0,
          y: 200
        });
        gsap.from("#item_block_26", {
          scrollTrigger: "#item_block_26",
          duration: 1,
          opacity: 0,
          y: 200
        });
        gsap.from("#item_block_27", {
          scrollTrigger: "#item_block_27",
          duration: 2,
          opacity: 0,
          y: 200
        });
        gsap.from("#item_block_28", {
          scrollTrigger: "#item_block_28",
          duration: 1,
          opacity: 0,
          y: 200
        });
        gsap.from("#item_block_29", {
          scrollTrigger: "#item_block_29",
          duration: 2.2,
          opacity: 0,
          y: 200
        });
        gsap.from("#item_block_30", {
          scrollTrigger: "#item_block_30",
          duration: 1,
          opacity: 0,
          y: 200
        });
        gsap.from("#item_block_31", {
          scrollTrigger: "#item_block_31",
          duration: 2,
          opacity: 0,
          y: 200
        });
        gsap.from("#item_block_32", {
          scrollTrigger: "#item_block_32",
          duration: 1,
          opacity: 0,
          y: 200
        });
        gsap.from("#item_block_33", {
          scrollTrigger: "#item_block_33",
          duration: 2.2,
          opacity: 0,
          y: 200
        });
        gsap.from("#item_block_34", {
          scrollTrigger: "#item_block_34",
          duration: 1,
          opacity: 0,
          y: 200
        });
        gsap.from("#item_block_35", {
          scrollTrigger: "#item_block_35",
          duration: 2,
          opacity: 0,
          y: 200
        });
        gsap.from("#item_block_36", {
          scrollTrigger: "#item_block_36",
          duration: 1,
          opacity: 0,
          y: 200
        });
        gsap.from("#item_block_37", {
          scrollTrigger: "#item_block_37",
          duration: 2.2,
          opacity: 0,
          y: 200
        });
        gsap.from("#item_block_38", {
          scrollTrigger: "#item_block_38",
          duration: 1,
          opacity: 0,
          y: 200
        });
        gsap.from("#item_block_39", {
          scrollTrigger: "#item_block_39",
          duration: 2,
          opacity: 0,
          y: 200
        });
        gsap.from("#item_block_40", {
          scrollTrigger: "#item_block_40",
          duration: 1,
          opacity: 0,
          y: 200
        });
        gsap.from("#item_block_41", {
          scrollTrigger: "#item_block_41",
          duration: 2,
          opacity: 0,
          y: 200
        });
        gsap.from("#item_block_42", {
          scrollTrigger: "#item_block_42",
          duration: 2.2,
          opacity: 0,
          y: 200
        });
        gsap.from("#item_block_43", {
          scrollTrigger: "#item_block_43",
          duration: 1,
          opacity: 0,
          y: 200
        });
        gsap.from("#item_block_44", {
          scrollTrigger: "#item_block_44",
          duration: 2,
          opacity: 0,
          y: 200
        });
   
      });
    });
  },
  methods: {
    gocase(id) {
      this.$router.push({
        name: "case",
        params: {
          id
        }
      });
    }
  }
};
</script>
 
<style scoped>
.swiper-container {
  width: 100vw;
  margin-top: 64px;
  height: 900px;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
}

.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 32px;
  left: 0;
  /* height: 100px; */
  width: 100%;
}

/deep/.swiper-pagination-bullet {
  width: 10px !important;
  height: 10px !important;
  display: inline-block;
  border-radius: 100%;
  background: #999;
  opacity: 0.2;
}

/deep/.swiper-pagination-bullet-active {
  opacity: 1;
  background: #fff;
  /* box-shadow: 0 0 20px 4px #999; */
  border: 2px solid #999;
}

/deep/.swiper-container-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: 0 10px;
}
.top {
  width: 100%;
  background-color: #6461a6;
  height: 64px;
  position: fixed;
  z-index: 999;
}

.logo {
  width: 75px;
  height: 37px;
  background-image: url(../assets/logo.png);
  background-size: 100% 100%;
  margin-left: 70px;
  margin-top: 13.5px;
  float: left;
}

.ability {
  font-size: 14px;
  float: right;
  margin-right: 9px;
  line-height: 64px;
  display: flex;
}

.items {
  color: #fff;
  margin-right: 61px;
  margin-top: 13.5px;
}

/* END */

/* 菜单与鼠标移入 */
.menu {
  width: 100%;
  height: 45px;
  line-height: 45px;
  text-align: center;
  position: relative;
  overflow: hidden;
  font-size: 14px;
  font-family: "SC_Light";
}

.menu:hover {
  z-index: 999;
  cursor: pointer;
  overflow: visible;
  font-family: "SC_Bold";
}

/* END */

/* 下拉菜单与鼠标移入 */

.dropbox {
  border-top: 2px solid #fff;
}

.drop {
  background: #6461a6;
  text-align: center;
  width: 96px;
  height: 45px;
  line-height: 45px;
  overflow: hidden;
  font-size: 14px;
  border-bottom: 1px solid #eee;
  font-family: "SC_Light";
}

.drop1 {
  background: transparent;
  text-align: center;
  width: 96px;
  height: 45px;
  line-height: 45px;
  overflow: hidden;
  font-size: 14px;
}

.drop:hover {
  font-family: "SC_Bold";
  cursor: pointer;
}
</style>

<style scoped>
.Selectedcasespic {
  width: 100%;
  height: 980px;
  background-image: url("../assets/Selectedcases.png");
  background-size: 100% 100%;
  margin-bottom: 100px;
}

.Selectedcasestitle {
  text-align: center;
  color: #333333;
  font-size: 35px;
  font-family:"SC_Bold";
  margin-bottom: 60px;
  margin-top: 100px;
}

.Selectedcasesfont {
  text-align: center;
  color: #333333;
  font-size: 18px;
  margin-bottom: 6px;
  margin-bottom: 80px;
}

.selectedCasesBox {
  width: 1830px; /*1804px; */
  margin: 0 auto 80px;
  display: flex;
  /* justify-content: space-between; */
  flex-wrap: wrap;
}

.selectedCasescenter {
  width: 601.333px;
  overflow: hidden;
  /* height: 9.375px; */
  background-color: #fff;

  /* margin-top: 20px; */
  /* min-height:7.375px;
  max-height: 9.375px; */
  transition: all 0.4s;
  margin-bottom: 40px;
  /* border: 2px solid black; */
  height: 450.333px;
  margin-left: 8px;
}
.selectedCasespic{
  height: 80%;
  border-radius: 12px;
}
.selectedCasespic img {
  border-radius: 12px;
  width: 99%;
  height: 99%;
  
}
.selectedCasescenter:hover {
  box-shadow: 0 8px 8px 0 #f2f2f2;
  /* transform: translate(0, -10px);  */
}
.selectedCasesIntroduce {
  font-size: 20px;
  color: #333333;
  font-family: "SC_Medium";
  margin-bottom: 10px;
  margin-top: 20px;
  padding-left: 10px;
}

.selectedCasesName {
  font-size: 14px;
  padding-left: 10px;
  color: #333333;
}


.pagination1 >>> .swiper-pagination-bullet {
  /* width: 65px !important;
  height: 3px !important;
  border-radius: 0px;

  border: none;
  opacity: 1; */
  background-color: transparent;
  border: 2px solid #fff;
  opacity: 1;
}
.pagination1 >>> .swiper-pagination-bullet-active {
  /* width: 65px !important;
  height: 3px !important;
  border-radius: 0px;
  background: #fff;
  border: none; */
  background-color: #fff;

}
</style>