<template>
  <div class="body">
    <div class="center">
      <div class="casepc">
        <!-- <img src="../assets/case1.png" alt /> -->

        <img :src="richvideoUrl" alt="richText" />
      </div>

      <div class="content">
        <div class="aaa" v-html="richText"></div>
      </div>
      <div class="title-box">
        <div class="title">相关行业案例</div>
      </div>
      <div class="moreCaseBody">
        <div class="moreCase">
          <div class="case" v-for="(item,key) of jumpToCase" :key="key" @click="ToCase(item.id)">
            <img :src="item.url" alt />
            <p class="border-font">{{item.title}}</p>
            <p class="boder-content">{{item.subTitle}}</p>
          </div>
          <!-- <div class="case" @click="ToCase(jumpToCase[1].id)">
            <img :src="jumpToCase[1].url" alt />
            <p class="border-font">{{jumpToCase[1].title}}</p>
            <p class="boder-content">{{jumpToCase[1].subTitle}}</p>
          </div>-->
        </div>
      </div>
      <!-- <div class="casetitle">中国-东盟信息港（数字广西）展示中心</div>
            <div class="casehead">项目背景</div>
            <div class="casefont">
                为全面贯彻落实习近平总书记关于“数字中国”建设的重要指示精神，以及在中国一东盟博览会、中国—东盟商务与投资峰会的重要致辞精神，充分展示中国—东盟信息港和数字广西建设成就，按照“定位科学、创新引领、内容真实、运行智能”的建设要求。将中国一东盟信息港（数字广西）展示中心建设成为集协同调度、成果展示、科技体验等功能一体的面向政府、企业、市民的智能展示，馆打造彰显数字广西特色的亮丽新名片。
            </div>
            <div class="casepic"></div>
            <div class="casepiclist">
                <div class="casepicleft"></div>
                <div class="casepicright"></div>
            </div>

            <div class="casehead">功能亮点</div>
            <div class="casefont">
                中国—东盟信息港（数字广西）展示中心集政府数据治理与成果展示于一体，打造数字协同调度指挥中心和政府成果展示中心。针对政府和企业受众，打造政用与商用场景。分为四大展区展现数字政府、数字经济、数字社会一体化发展的具体成果。
            </div>
            <div class="casepic"></div>
            <div class="casepiclist">
                <div class="casepicleft"></div>
                <div class="casepicright"></div>
      </div>-->
    </div>
  </div>
</template>

<script>
import $ from "jquery";
export default {
  watch: {
    $route() {
      if (this.$route.params.id !== undefined) {
        let id = this.$route.params.id;
        this.$axios(`getCaseDetail?id=${id}`).then(res => {
          this.richText = res.data.data.text;
          this.richvideoUrl = res.data.data.videoUrl;
          console.log("banner————res———— ", res.data);
          // this.richvideoUrl = res.data.data.videoUrl;
        });
        this.$axios(`getCaseRecommended?id=${id}`).then(res => {
          let newArr = [];
          newArr.push(res.data.data[0], res.data.data[1]);
          this.jumpToCase = newArr;
        });
      }
    }
  },
  beforeMount() {
    let id = this.$route.params.id;
    this.$axios(`getCaseDetail?id=${id}`).then(res => {
      this.richText = res.data.data.text;
      this.richvideoUrl = res.data.data.videoUrl;
      console.log("this.richvideoUrl", this.richvideoUrl);
      // this.richvideoUrl = '/img/case1.1a3b91bd.png';
    });
    this.$axios(`getCaseRecommended?id=${id}`).then(res => {
      let newArr = [];
      newArr.push(res.data.data[0], res.data.data[1]);
      this.jumpToCase = newArr;
    });
    console.log("beforeCreate");
  },
  mounted() {},
  destroyed() {},
  updated() {
    $(".aaa")
      .find("img")
      .css({
        width: "62.26042vw",
        margin: "0 auto",
        display: "block"
      });
    $(".aaa")
      .find("video")
      .css({
        width: "62.26042vw",
        margin: "0 auto",
        display: "block"
      });
    $(".aaa")
      .find("p")
      .css({
        width: "62.26042vw",
        margin: "0 auto",
        display: "block",
        fontFamily: "SC_Light"
      });

    var video = document.querySelector("video");
    if (video) {
      video.setAttribute("width", "100%");
      video.setAttribute("height", "100%");
      video.setAttribute("controls", "false");
      // video.setAttribute("muted", "true");
      let muted = document.createAttribute("muted");
      muted.value = true;
      let autoplay = document.createAttribute("autoplay");
      autoplay.value = true;
      video.setAttributeNode(autoplay);
      video.setAttributeNode(muted);
    }
  },
  created() {},
  data() {
    return {
      richText: "",
      jumpToCase: [
        // {
        //   id: 124,
        //   sort: 0,
        //   subTitle: "“未来智造”新模式",
        //   title: "紫光集团智能制造创新体验中心",
        //   url:
        //     "http://36.134.151.244:4529/658365c8-48d9-434d-b1b2-33570d834cec.jpeg"
        // },
        // {
        //   id: 124,
        //   sort: 0,
        //   subTitle: "“未来智造”新模式",
        //   title: "紫光集团智能制造创新体验中心",
        //   url:
        //     "http://36.134.151.244:4529/658365c8-48d9-434d-b1b2-33570d834cec.jpeg"
        // }
      ],
      richvideoUrl: ""
    };
  },
  methods: {
    ToCase(id) {
      this.$router.push({
        name: "case",
        params: {
          id
        }
      });
    },

    bofang() {
      var video = document.querySelector("video");
      video.play();
    }
  }
};
</script>


<style >
.center {
  margin-bottom: 50px;
}
.top {
  width: 100%;
  background-color: #6461a6;
  height: 64px;
  position: fixed;
  z-index: 999;
}

.logo {
  width: 75px;
  height: 37px;
  background-image: url(../assets/logo.png);
  background-size: 100% 100%;
  margin-left: 70px;
  margin-top: 13.5px;
  float: left;
}

.ability {
  font-size: 14px;
  float: right;
  margin-right: 9px;
  line-height: 64px;
  display: flex;
}

.items {
  color: #fff;
  margin-right: 61px;
  margin-top: 13.5px;
}

/* END */

/* 菜单与鼠标移入 */
.menu {
  width: 100%;
  height: 45px;
  line-height: 45px;
  text-align: center;
  position: relative;
  overflow: hidden;
  font-size: 14px;
  font-family: "SC_Light";
}

.menu:hover {
  z-index: 999;
  cursor: pointer;
  overflow: visible;
  font-family: "SC_Bold";
}

/* END */

/* 下拉菜单与鼠标移入 */

.dropbox {
  border-top: 2px solid #fff;
}

.drop {
  background: #6461a6;
  text-align: center;
  width: 96px;
  height: 45px;
  line-height: 45px;
  overflow: hidden;
  font-size: 14px;
  border-bottom: 1px solid #eee;
  font-family: "SC_Light";
}

.drop1 {
  background: transparent;
  text-align: center;
  width: 96px;
  height: 45px;
  line-height: 45px;
  overflow: hidden;
  font-size: 14px;
}

.drop:hover {
  font-family: "SC_Bold";
  cursor: pointer;
}
</style>

<style scoped>
.content {
  width: 100%;
  /* height: 640px; */
  margin: 0 auto;
}
.casepc {
  width: 100%;
  height: 965px;

  margin-bottom: 100px;
}
.casepc img {
  width: 100%;
  height: 100%;
}

.casetitle {
  text-align: center;
  color: #333333;
  font-size: 30px;
  font-family: "SC_Black";
  margin-bottom: 50px;
}

.casehead {
  text-align: center;
  color: #333333;
  font-size: 24px;
  margin-bottom: 30px;
}

.casehead::before {
  content: "";
  width: 10px;
  height: 10px;
  background-color: #6461a6;
  display: inline-block;
  transform: rotate(45deg);
  margin-right: 15px;
  margin-bottom: 2px;
}

.casehead::after {
  content: "";
  width: 10px;
  height: 10px;
  background-color: #6461a6;
  display: inline-block;
  transform: rotate(45deg);
  margin-left: 15px;
  margin-bottom: 2px;
}

.casefont {
  width: 1200px;
  margin: 0 auto;
  font-size: 16px;
  font-family: "SC_Light";
  line-height: 30px;
  margin-bottom: 30px;
}

.casepic {
  width: 1200px;
  height: 640px;
  margin: 0 auto;
  background-image: url("../assets/case2.png");
  background-size: 100% 100%;
  margin-bottom: 10px;
}

.casepiclist {
  width: 1200px;
  height: 340px;
  margin: 0 auto;
  margin-bottom: 80px;
  display: flex;
}

.casepicleft {
  width: 595px;
  height: 340px;
  background-image: url("../assets/case3.png");
  background-size: 100% 100%;
  margin-right: 10px;
}

.casepicright {
  width: 595px;
  height: 340px;
  background-image: url("../assets/case4.png");
  background-size: 100% 100%;
}
.enen {
  width: 773px;
  color: red;
  margin: 0 auto;
  display: block;
}
.moreCase {
  /* padding-left: 19%; */
  display: flex;
  /* background-color: red; */
  justify-content: center;
  align-items: center;
}
.moreCase .case {
  margin-left: 30px;
  /* margin:  0 auto; */
}
.moreCase img {
  /* width: 656px; */
  border-radius: 5px;
  display: block;
  height: 340px;
  width: 566px;
  /* padding-top: 60%; */
  /* margin-left: 30px; */
}
.border-font {
  width: 70%;
  text-align: center;
  /* line-height: 30px; */
  font-size: 16px;
  margin: 29px auto 0;
  font-family: "SC_Bold";
  color: #333333;
  margin-bottom: 5px;
}
.boder-content {
  text-align: center;
  font-size: 12px;
}

.title-box {
  margin-top: 100px;
  margin-bottom: 30px;
}
.title-box .title,
.ex-title {
  margin: 0 auto;
  width: 500px;
  text-align: center;
  margin-left: 725px;
}
.title-box .title {
  color: #333333;
  font-family: "SC_Bold";
  margin-bottom: 10px;
  font-size: 30px;
}
</style>