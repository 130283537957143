<template>
  <div class="toTop1">
 
    <div class="item" style="border-bottom:  1.5px #9e9bc2 solid;" @click="showContentUs">
      <img src="../assets/images/toTop/contentUs.png" alt />
    </div>
    <div class="item" style="border-bottom:  1.5px #9e9bc2 solid;" @click="showPopup">
      <img src="../assets/images/toTop/编组 8.png" alt />
    </div>
    <div class="item" @click="topTop">
      <img src="../assets/images/toTop/Fill 11.png" alt/>
    </div>
    <div>
      <!-- <img src="../../assets/images/toTop/编组 8.svg" alt=""> -->
    </div>
  </div>
</template>

<script>
export default {
  methods: {

    showContentUs(){
      console.log('1')
      this.$bus.$emit("valueUpdateContentUs", true);
      this.$bus.$emit("valueUpdate", false);
    },
    showPopup() {
      this.$bus.$emit("valueUpdate", true);
      this.$bus.$emit("valueUpdateContentUs", false);
    },
    topTop() {
      document.documentElement.scrollTop = 0;
    }
  }
};
</script>

<style  scoped>
.toTop1 {
  position: fixed;
  z-index: 1000000;
  top: 70%;
  height: 150px;
  background-color: #fff;
  right: 0;
  width: 50px;
  border: 1px #9e9bc2 solid;
}
.toTop1 .item {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.toTop1 .item img {
  width: 24px;
  display: block;
}
.topTop img{
  height:24px;width:50px
}
</style>