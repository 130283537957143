import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/global.css'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// import './util/rem'
import axios from 'axios'
// import AFTableColumn from 'af-table-column'
import './assets/font/HarmonyOS_Sans_SC/font.css'
import Video from 'video.js'
import 'video.js/dist/video-js.css'


// 定义懒加载图片或者文件等，自定义指令
Vue.directive('lazy', (el, binding) => {
  let oldSrc = el.src //保存旧的src，方便后期渲染时候赋值src真实路径
  el.src = "" //将渲染的src赋为空，则不会渲染图片出来
  let observer = new IntersectionObserver(([{ isIntersecting }]) => { // 调用方法得到该elDOM元素是否处于可视区域
    if (isIntersecting) { //回调是否处于可视区域，true or false
      el.src = oldSrc //如果处于可视区域额，将最开始保存的真实路径赋予DOM元素渲染
      observer.unobserve(el) // 只需要监听一次即可，第二次滑动到可视区域时候不在监听
    }
  })
  observer.observe(el) // 调用方法
})

Vue.prototype.$video = Video
Vue.prototype.$bus = new Vue()
router.afterEach((to,from,next)=>{
  window,scrollTo(0,0)
})
//设置根路径
axios.defaults.baseURL = 'http://123.60.166.31:9999/app/api/'
// axios.defaults.baseURL = 'http://192.168.20.65:9999/app/api/'
// axios.defaults.baseURL = 'http://36.134.151.244:9999/app/api/'
// axios.defaults.baseURL = 'http://192.168.20.9:9999/app/api/'
// axios.defaults.baseURL = 'http://192.168.1.42:9999/app/api'
// axios.defaults.baseURL = 'http://192.168.20.132:9999/app/api/'
//设置请求拦截器------请求前给请求加token
axios.interceptors.request.use(config => {
  //通过config.headers.Authorization把登录后存储在本地的token绑定在headers上
  config.headers= {
      'Access-Token':  window.sessionStorage.getItem('token')
  }
  return config
})
//axios原型挂载到vue原型链上
Vue.prototype.$axios = axios
Vue.config.productionTip = false

Vue.use(ElementUI);
// Vue.use(AFTableColumn)
new Vue({
  router,
  store,
  render: h => h(App),
  beforeCreate(){                 //在初始化阶段前
    Vue.prototype.$bus = this   //配置全局总线，bus有总线的意思
}
}).$mount('#app')
