<template>
  <div>
    <el-dialog :visible.sync="dialogVisible" width="40%" :show-close="false">
      <div class="box">
        <div class="title">联系我们</div>
        <div class="subTitle">您可在线填写信息，我们的销售代表会尽快与您联系</div>

        <div class="inputGroup">
          <div class="inputItem">
            <div class="left">姓名</div>
            <div class="right">
              <input type="text" v-model="name" />
            </div>
          </div>
          <div class="inputItem">
            <div class="left">手机</div>
            <div class="right">
              <input type="tel" maxlength ="11"  v-model="phoneNumber" />
            </div>
          </div>
          <div class="inputItem">
            <div class="left">邮箱</div>
            <div class="right">
              <input type="email" v-model="email" />
            </div>
          </div>
          <div class="inputItem">
            <div class="left">行业</div>
            <div class="right">
              <input type="text" v-model="industry" />
            </div>
          </div>
          <div class="inputItem">
            <div class="left">省份</div>
            <div class="right">
              <input type="text" v-model="economize" />
            </div>
          </div>
          <div class="inputItem">
            <div class="left">城市</div>
            <div class="right">
              <input type="text" v-model="city" />
            </div>
          </div>
        </div>

        <div class="inputItem sigle">
          <div class="left sigle2">公司名称</div>
          <div class="right">
            <input type="text" v-model="compony" />
          </div>
        </div>
        <div class="inputItem sigle">
          <div class="left sigle3">需求描述</div>
          <div class="right" style="border:none">
            <textarea v-model="demand" class="textarea" placeholder="请简单描述下业务需求" name id cols="30" rows="10"></textarea>
          </div>
        </div>

        <div class="button" @click="submit">确认提交</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
export default {
  mounted() {
    this.$bus.$on("valueUpdateContentUs", value => {
      console.log(value);
      this.dialogVisible = value;
    });
  },
  computed: {
    ...mapState(["isShowContentUs"])
  },
  data() {
    return {
      dialogVisible: this.isShowContentUs,
      //   dialogVisible: true,
      name: "",
      phoneNumber: "",
      email: "",
      industry: "",
      economize: "",
      city: "",
      compony: "",
      demand:''
    };
  },
  methods: {
    submit() {
      let pattern = /^1[3456789]\d{9}$/;
      var patternEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if(this.name==''||this.phoneNumber==''|| this.email==''||this.industry==''||this.economize==''||this.city==''||this.compony==''){

        this.$message.error('请完整填写信息');
        return

      }

      if(pattern.test(this.phoneNumber)==false){

        this.$message.error('请输入正确的手机号');
        return
      }
      if(patternEmail.test(this.email)==false){

        this.$message.error('请输入正确的邮箱');
        return
      }

      this.$axios.post("feedback/save", {
        name: this.name,
        phone: this.phoneNumber,
        email: this.email,
        inducy: this.industry,
        economize: this.economize,
        city: this.city,
        compony:this.compony,
        demand:this.demand
      }).then(res=>{
        console.log(res)
        if(res.data.code==200){
          this.dialogVisible=false
          this.$message({
            message: '提交成功,后续会有工作人员联系您',
          type: 'success'
          })
        }else{
          this.$message.error('服务器错误');
        }
      });
    }
  }
};
</script>

<style scoped>
.sigle3 {
  width: 80px !important;
  margin-right: 10px;
}
.textarea {
  width: 570px;
  height: 66px;
  resize: none; /* 禁用拖动调整大小功能 */
  border: none;
  background-color: #f5f5f5;
  /* margin-left: 1px; */
  font-family: "SC_Medium";
  padding: 10px;
  font-size: 14px;
}
.textarea:focus {
  outline: none;
}
.sigle2 {
  width: 80px !important;
}
.sigle {
  margin-left: 24px;
  width: 665px !important;
}
.box {
  height: 400px;
  /* width: 527px; */
}
.title {
  font-family: "SC_Medium";
  font-size: 30px;
  color: #000;

  margin-left: 24px;
  margin-top: -10px;
  border-left: 5px solid #3c3a66;
  padding-left: 20px;
  height: 30px;
  line-height: 30px;
}
.subTitle {
  margin-left: 24px;
  font-family: "SC_Light";
  font-size: 14px;
  margin-top: 11px;
  margin-bottom: 20px;
}
.inputGroup {
  display: flex;
  margin-left: 24px;
  width: 778px;
  flex-wrap: wrap;
}
.inputItem {
  width: 284px;
  display: flex;
  margin-bottom: 23px;
}
.inputItem:nth-child(odd) {
  margin-right: 95px;
}
.left {
  width: 40px;
  font-family: "SC_Light";
  font-size: 14px;
}
.right {
  border-bottom: 1px solid #979797;
  width: 94%;
  margin-left: 1px;
}
input {
  border: none;
  width: 100%;
  margin-top: 4px;
}
input:focus {
  outline: none;
}
.button {
  width: 190px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  margin: 0 auto;
  font-family: "SC_Light";
  color: #fff;
  font-size: 16px;
  background-color: #3d3a66;
}
</style>