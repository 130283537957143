<template>
    <div class="category">
  
        <div class="categoryTop">
   

            <video
       

       ref="video"
       class="video-js vjs-default-skin"
       autoplay
       muted
       loop
     >
       <source src="https://bk.sidex.cn/static/startPoint.mp4" />
     </video>

            <!-- <div class="inputButton">
                <el-input placeholder="输入职位进行搜索" class="inputStyle" v-model="inp"></el-input>
                <button class="search"><i class="el-icon-search"></i></button>
            </div> -->
        </div>

        <div class="categoryBottom">
            <div class="entrance">
                <div class="socialRecruitment fl" @click="Social">
                    <img src="@/assets/images/socialImg01.png" />
                    <div class="socialRecruitmentText">
                        <p class="p1">社会招聘</p>
                        <p class="p2"><span>职场老炮</span><span>专业人士</span></p>
                    </div>
                </div>
                <div class="socialRecruitment fr" @click="Campus">
                    <img src="@/assets/images/socialImg02.png" />
                    <div class="socialRecruitmentText">
                        <p class="p1">校园招聘</p>
                        <p class="p2"><span>职场新秀</span><span>萌新小白</span></p>
                    </div>
                </div>
            </div>

        </div>
        <div class="bg_hse">
            <div class="workSIde">
                <div class="workSIdeLeft fl">
                    <p class="p3">工作在思德</p>
                    <div class="lineHX"></div>
                    <div class="wushi">
                        <p class="p4">务实高效的工作方式</p>
                        <p class="p5">关注本质，杜绝形式主义，重视用户反馈和实际效果；提倡创新创造，保持活力，坚持高标准，鼓励为更好的结果做突破性尝试。</p>
                    </div>
                    <div class="wushi">
                        <p class="p4">扁平化管理模式</p>
                        <p class="p5">不讲title，不论资排辈，可对Leader直呼其名；不做“向上管理”，避免过度包装而忽略实际效果；内部信息公开透明，资源开放共享，共成长。</p>
                    </div>
                    <div class="wushi">
                        <p class="p4">极具竞争力的用人体系</p>
                        <p class="p5">尊重个体差异，相信全球化的多元视角能带来更多启发，招人不唯背景和资历，只要本质优秀，都能获得发展机会。</p>
                    </div>
                </div>
                <img src="@/assets/images/jobImg01.png" class="fl" />
            </div>
        </div>

        <div class="bg_bse">
            <div class="workSIde">
                <img src="@/assets/images/jobImg02.png" class="fl" />
                <div class="workSIdeRight fl">
                    <p class="p3 TXbottom">晋升体系</p>
                    <p class="p5 TXtop">为了有效提升大家的成长空间，公司制定了完善公平公开的晋升体系，针对各岗位明确职级划分标准以及晋升通道。</p>
                    <div class="lineHX"></div>
                    <div class="wushi">
                        <p class="p4">六大岗位职系</p>
                        <div class="tenJob">
                            <span>经营管理类</span>
                            <span>市场营销类</span>
                            <span>信息技术类</span>
                            <span>设计策划类</span>
                            <span>项目管理类</span>
                            <span>综合管理类</span>
                        </div>
                    </div>
                    <div class="wushi">
                        <p class="p4">十大岗位职级</p>
                        <p class="p5">10个职级由低到高使用 S1~S10 标识；不同的职级对应不同的薪酬带宽、福利等待遇；</p>
                    </div>
                    <div class="wushi">
                        <p class="p4">完善的晋升标准及通道</p>
                        <p class="p5">岗位晋升均实行双通道管理，即专业条线晋升和管理条线晋升；原则上以年度为单位，进行晋升评比，依据评比结果，进行相应的职级调整；如有突出贡献，可特殊处理。</p>
                    </div>
                </div>

            </div>
        </div>

        <div class="bg_hse">
            <div class="welfare">
                <p class="fuli">员工福利</p>
                <div class="welfareList welfareLeft">
                    <img src="@/assets/images/welfareImg01.png" />
                    <p class="p6">弹性工作</p>
                    <p class="p7">灵活安排选择工作时间</p>
                </div>
                <div class="welfareList">
                    <img src="@/assets/images/welfareImg02.png" />
                    <p class="p6">六险一金</p>
                    <p class="p7">缴纳养老／生育／失业／医疗／工伤保险/商业意外险、住房公积金</p>
                    <!-- <p class="bx">缴纳养老/生育/失业/医疗</p>
                    <p class="bx">商业意外险、工伤保险和住房公积金</p> -->
                </div>
                <div class="welfareList">
                    <img src="@/assets/images/welfareImg03.png" />
                    <p class="p6">定期体检</p>
                    <p class="p7">阶段性职业健康检查</p>
                </div>
                <div class="welfareList">
                    <img src="@/assets/images/welfareImg04.png" />
                    <p class="p6">带薪年假</p>
                    <p class="p7">入职一年即可享受法定带薪年假</p>
                </div>
                <div class="welfareList welfareLeft">
                    <img src="@/assets/images/welfareImg05.png" />
                    <p class="p6">节日福利</p>
                    <p class="p7">法定节日惊喜礼包<i>/</i>生日专属礼物</p>
                </div>
                <div class="welfareList">
                    <img src="@/assets/images/welfareImg06.png" />
                    <p class="p6">成长激励</p>
                    <p class="p7">年终奖<i>/</i>绩效奖金<i>/</i>股权激励<i>/</i>升职晋升</p>
                </div>
                <div class="welfareList">
                    <img src="@/assets/images/welfareImg07.png" />
                    <p class="p6">员工旅游</p>
                    <p class="p7">定期组织员工团建旅游，工作生活两不误</p>
                </div>
                <div class="welfareList">
                    <img src="@/assets/images/welfareImg08.png" />
                    <p class="p6">加班补贴</p>
                    <p class="p7">交通补贴<i>/</i>餐饮</p>
                </div>
            </div>
        </div>

    </div>
</template>

<script type="text/javascript">
export default {
    name: 'joinUs',
    data() {
        return {
            inp:''
        }
    },
    methods: {
        Social(){
            this.$router.push("/category")
        },
        Campus(){
            this.$router.push("/Campus")
        }
    }
};
</script>

<style scoped>
.top {
    width: 100%;
    background-color: #6461a6;
    height: 64px;
    position: fixed;
    z-index: 999;
}

.logo {
    width: 75px;
    height: 37px;
    background-image: url(../../assets/logo.png);
    background-size: 100% 100%;
    margin-left: 70px;
    margin-top: 13.5px;
    float: left;
}

.ability {
    font-size: 14px;
    float: right;
    margin-right: 9px;
    line-height: 64px;
    display: flex;
}


.items {
    color: #fff;
    margin-right: 61px;
    margin-top: 13.5px;
}

/* END */

/* 菜单与鼠标移入 */
.menu {
    width: 100%;
    height: 45px;
    line-height: 45px;
    text-align: center;
    position: relative;
    overflow: hidden;
    font-size: 14px;
    font-family: "SC_Light"
}


.menu:hover {
    z-index: 999;
    cursor: pointer;
    overflow: visible;
    font-family: "SC_Bold";
}

/* END */

/* 下拉菜单与鼠标移入 */

.dropbox {
    border-top: 2px solid #fff;
}

.drop {
    background: #6461a6;
    text-align: center;
    width: 96px;
    height: 45px;
    line-height: 45px;
    overflow: hidden;
    font-size: 14px;
    border-bottom: 1px solid #eee;
    font-family: "SC_Light";
}

.drop1 {
    background: transparent;
    text-align: center;
    width: 96px;
    height: 45px;
    line-height: 45px;
    overflow: hidden;
    font-size: 14px;

}

.drop:hover {
    font-family: "SC_Bold";
    cursor: pointer;
}
</style>
<style scoped>
.fl {
    float: left;
}

.fr {
    float: right;
}

.category {
    width: 100%;

    overflow: hidden;
}

.categoryTop {
    width: 100%;
    margin-bottom: 100px;
    height: 900px;
    position: relative;
    margin-top: 64px;
}
.video-js {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.jobBanner {
    width: 100%;
}

.inputButton {
    /**384  40 */
    width: 512px;
    height: 53.33px;
    position: absolute;
    top: 525px;
    right: 165px;
}

.el-input {
    width: 512px;
}

.inputStyle>>>input {
    height: 53.33px;
    border-radius: 40px;
    text-indent: 24px;
    font-size: 16px;
    border: none;
    background: #f2f2f2;
}

.search {
    width: 53.33px;
    height: 53.33px;
    border-radius: 50%;
    color: #fff;
    background: #6461a6;
    border: none;
    font-size: 18px;
    font-family: 'SC_Bold';
    position: absolute;
    right: 0px;
    top: 0px;
}

.categoryBottom {
    width: 790px;
    overflow: hidden;
    margin: 0 auto;
}

.entrance {
    width: 790px;
    /*744 + 40 */
    overflow: hidden;
    padding-top: 30px;
    padding-bottom: 60px;
    margin: 0 auto;
}

.socialRecruitment {
    width: 380px;
    height: 591px; transition: all 0.4s;

}
.socialRecruitment:hover {

    box-shadow: 0 8px 8px 0 #f2f2f2;  
}

.socialRecruitment img {
    width: 380px;
    height: 499px;
    float: left;
}

.socialRecruitmentText {
    width: 380px;
    height: 92px;
    box-shadow: 7px 5px 23px #f2f2f2;
    text-align: left;
    float: left;
}

.socialRecruitmentText .p1 {
    font-size: 20px;
    line-height: 40px;
    color: #333333;
    font-family: 'SC_Black';
    text-indent: 1.3em;
    margin: 10px auto 0;
}

.socialRecruitmentText .p2 {
    font-size: 14px;
    line-height: 20px;
    text-indent: 1em;
    color: #333333;
    opacity: 0.6;
    font-family: 'SC_Light';
    margin: 0 auto;
}

.socialRecruitmentText .p2 span {
    display: inline-block;
}

.bg_hse {
    width: 100%;
    background: #f2f2f2;
    padding-top: 40px;
    overflow: hidden;
    padding-bottom: 70px;
}

.bg_bse {
    width: 100%;
    background: #ffffff;
    overflow: hidden;
    padding-top: 70px;
    padding-bottom: 70px;
}

.workSIde {
    width: 1200px;
    overflow: hidden;
    margin: 0 auto;
}

.workSIde img {
    width: 760px;
    height: 420px;
    display: block;
}

.workSIdeLeft {
    width: 370.2px;
    /* height:420px; */
    margin-right: 69.8px;
}

.workSIdeRight {
    width: 370.2px;
    /* height:420px; */
    /* overflow: hidden; */
    margin-left: 69.8px;
}

.p3 {
    font-size: 26px;
    line-height: 33px;
    color: #000000;
    font-family: 'SC_Medium';
    text-align: left;
    margin-top: 15px;
    margin-bottom: 20px;
}

.TXbottom {
    margin-bottom: 0px;
}

.TXtop {
    margin-top: 0px;
}

.lineHX {
    width: 82%;
    border: 1px solid #333333;
    opacity: 0.2;
    margin-bottom: 25px;
}

.wushi {
    overflow: hidden;
    margin-bottom: 12.5px;
}

.wushi p {
    color: #000000;
    text-align: left;
    margin: 0;
}

.p4 {
    font-size: 18px;
    font-family: 'SC_Bold';
    line-height: 32px;
}

.p5 {
    font-size: 14px;
    opacity: 0.8;
    font-family: 'SC_Regular';
    line-height: 18px;
}

.tenJob {
    width: 100%;
    overflow: hidden;
    margin-top: 5px;

}

.tenJob span {
    width: 113px;
    height: 37px;
    line-height: 37px;
    display: block;
    float: left;
    text-align: center;
    font-size: 14px;
    margin-right: 10px;
    margin-bottom: 3.5px;
    border: 1px solid #cccccc;
}

.tenJob span:nth-child(3),
.tenJob span:last-child {
    margin-right: 0px;
}

.tenJob span:nth-child(4),
.tenJob span:nth-child(5),
.tenJob span:last-child {
    margin-bottom: 0px;
}

.welfare {
    width: 1200px;
    margin: 0 auto;
}

.fuli {
    text-align: center;
    color: #333333;
    font-size: 35px;
    font-family:"SC_Bold";
    margin-bottom: 60px;
    margin-top: 100px;
}

.welfareList {
    width: 243.34px;
    /**270 350 */
    height: 350px;
    background: #ffffff;
    border-top: #6461a6 1px solid;
    float: left;
    margin-right: 20px;
    margin-bottom: 40px;
    padding-left: 13.33px;
    padding-right: 13.33px;
}

.welfareLeft {
    margin-left: 30px;
}

.welfareList:nth-child(5),
.welfareList:last-child {
    margin-right: 0;
}

.welfareList img {
    width: 168px;
    height: 168px;
    display: block;
    margin: 40px auto 30px;
}

.welfareList .p6 {
    font-size: 22px;
    font-family: 'SC_Bold';
    color: #000000;
    text-align: center;
    margin: 0;
}

.welfareList .p7 {
    font-size: 16px;
    font-family: 'SC_Regular';
    color: #606060;
    opacity: 0.8;
    /* padding-top: 5px; */
    text-align: center;
}
.bx{
    font-size: 16px;
    font-family: 'SC_Regular';
    color: #606060;
    opacity: 0.8;
    /* padding-top: 5px; */
    text-align: center;
    margin: 0;
    margin-top: 10px;
}
.welfareList .p7 i {
    font-style: normal;
    margin-left: 2px;
    margin-right: 2px;
}</style>