<template>
  <div class="body">

    <div class="center">
      <div class="picture">
        <div class="serviceBox">
          <div class="servicetitle">全生命周期服务</div>
          <div class="servicefont">以客户为中心，以结果为导向</div>
          <div class="servicefont">提供数智化体验营销项目的咨询规划、</div>
          <div class="servicefont">创意设计、建设交付和运营管理</div>
        </div>
      </div>
      <div class="tabbox">
        <div :class="[{'tabname2': cur===index}, 'tabname']"  v-for="(item, index) in tabTitle" @click="change(index)" :key="index"
          >{{ item }}</div>
      </div>

      <div class="tab-content">
        <div class="tabconter" v-show="cur == 0"> 
          <div class="tabcontent2"></div>
      
        </div>
        <div class="tabconter" v-show="cur == 1">
          <div class="tabcontent6"></div>
        </div>
        <div class="tabconter" v-show="cur == 2">
          <div class="tabcontent3"></div>
        </div>
        <div class="tabconter" v-show="cur == 3">
          <div class="tabcontent4"></div>
        </div>
        <div class="tabconter" v-show="cur == 4">
          <div class="tabcontent5"></div>
        </div>
        <div class="tabconter" v-show="cur == 5">
          <div class="tabcontent1"></div>
        </div>
      </div>

    </div>
    <div class="foot"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tabTitle: ['策略咨询', '资源整合', '项目交付', '智能技术', '业务包装','运营管理'],
      cur: 0 //默认选中第一个tab
    }
  },
  methods: {
    change(index){
      this.cur = index
    }
  },
}
</script>



<style scoped>
.center{
  margin-bottom: 0;


}
.picture {
  width: 100%;
  height: 805px;
  background-image: url("../assets/serverpic.png");
  background-size: 100% 100%;
}

.serviceBox {
  position: relative;
  top: 60%;
  transform: translateY(-60%);
}

.servicetitle {
  color: #333;
  text-align: center;
  font-family: "SC_Bold";
  letter-spacing: 1px;
  margin-bottom: 12px;
  font-size: 80px;
}

.servicefont {
  color: #333;
  text-align: center;
  font-size: 30px;
  margin-bottom: 7px;
  font-family: "SC_Light";
}

.tabbox {
  height: 60px;
  display: flex;
  font-size: 20px;
  font-family: "SC_Medium";
  line-height: 60px;
  /* justify-content: space-evenly; */
  padding-left: 375px;

}

.tabname {
  margin-right: 157px;
  transition: all 0.1s;
  cursor: pointer;
}
.tabname2{
  cursor: pointer;
  border-bottom: 3px solid #6461a6;
}
.active {
  border-bottom: 3px solid #6461a6;
}

.tabconter {
  width: 100%;
  height: 700px;
  background-color: #f2f2f2;
  padding-top: 80px;
}

.tabcontent1 {
  width: 1170px;
  height: 577.25px;
  margin: 0 auto;
  background-size: 100% 100%;
  background-image: url(../assets/tab1.png);
}

.tabcontent2 {
  width: 1170px;
  height: 577.25px;
  margin: 0 auto;
  background-size: 100% 100%;
  background-image: url(../assets/tab2.png);
}

.tabcontent3 {
  width: 1170px;
  height: 577.25px;
  margin: 0 auto;
  background-size: 100% 100%;
  background-image: url(../assets/tab3.png);
}

.tabcontent4 {
  width: 1170px;
  height: 577.25px;
  margin: 0 auto;
  background-size: 100% 100%;
  padding-top: 30px;
  background-image: url(../assets/tab4.png);
}

.tabcontent5 {
  width: 1170px;
  height: 577.25px;
  margin: 0 auto;
  background-size: 100% 100%;
  background-image: url(../assets/tab5.png);
}
.tabcontent6 {
  width: 1170px;
  height: 577px;
  margin: 0 auto;
  background-size: 100% 100%;
  background-image: url(../assets/tab6.png);
}
</style>