<template>
  <div class="body">
    <!-- 视频弹窗 -->
    <div class="videoPoup" v-if="showVideoPoup">
      <div class="cancel" @click="hide">
        <img src="../assets/videoCancel.png" alt />
      </div>
      <video
        ref="video"
        class="vjs-default-skin image.png vjs-big-play yesBlack"
        autoplay
        controls
        loop
        width="100%"
        height="100%"
        :src="videoUrl"
      ></video>
    </div>
    <div class="homeCenter">
      <!-- 视频轮播图 -->
      <div class="homeSwiper">
        <div class="swiper-container swiper1">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(item,k) in banner" :key="k">
              <img :src="item.url" alt v-if="item.fileType=='image'" />
              <video
                ref="video"
                class="video-js vjs-default-skin image.png vjs-big-play"
                autoplay
                loop
                muted
                width="100%"
                height="100%"
                v-if="item.fileType=='video'"
                :src="item.url"
              ></video>
              <div class="text-group">
                <p class="title">{{item.title}}</p>
                <p class="content">{{item.subTitle}}</p>
                <div
                  class="button"
                  @click="playvideo(item)"
                  v-if="item.fileType=='video'"
                  e.target.dataset.id
                >
                  <img src="../assets/watchVideo.png" alt />
                </div>
              </div>
            </div>
          </div>

          <!-- 如果需要分页器 -->
          <div class="swiper-pagination pagination1"></div>

          <!-- <div class="swiper-button-prev swiper-button-white"></div>
          <div class="swiper-button-next swiper-button-white"></div>-->
        </div>
      </div>

      <!-- 行业场景 -->
      <div class="Industryscenario">
        <div class="IndustryscenarioTitle">行业场景</div>
        <div class="swiperBox">
          <div class="swiper2-button-prev"></div>
          <div class="swiper-container swiper2">
            <div class="swiper-wrapper">
              <div
                class="swiper-slide slide2"
                v-for="(item,k) in Industry"
                :key="k"
                :data-id="item.id"
              >
                <div class="inner" :data-id="item.id">
                  <div class="borderpic">
                    <img :src="item.url" alt :data-id="item.id" />
                  </div>
                  <div class="border-font">{{item.title}}</div>
                </div>
              </div>
            </div>
          </div>

          <div class="swiper2-button-next"></div>
        </div>
      </div>
      <!-- 解决方案 -->
      <div class="Solution">
        <div class="SolutionTitle">解决方案</div>

        <div class="SolutionBox">
          <div
            v-for="(item,k) in jumpToSolution"
            :key="k"
            class="solutioncenter"
            :id="`item_block_${k+1}`"
            @click="jumpToSolutionEvne(item.id)"
          >
            <div class="solutionpic">
              <img :src="item.url" alt />
            </div>
            <div class="solutionIntroduce">{{item.smallTitle}}</div>
            <div class="solutionName">{{item.subTitle}}</div>
          </div>
        </div>
        <div class="Learnmore" @click="jumpToSoluitionDaday">
          了解更多解决方案
          <img src="../assets/more.png" alt class="rightArrow" />
        </div>
      </div>
      <!-- 精选案例 -->
      <div class="selectedCases">
        <div class="SolutionTitle">精选案例</div>
        <div class="selectedCasesBox">
          <div
            class="selectedCasescenter"
            v-for="(item,k) of jumpToCase"
            :key="k"
            @click="jumpToCaseEvent(item.id)"
          >
            <div class="selectedCasespic">
              <img :src="item.url" alt />
            </div>
            <div class="selectedCasesIntroduce">{{item.title}}</div>
            <div class="selectedCasesName">{{item.subTitle}}</div>
          </div>
        </div>
        <!-- 了解更多服务案例 -->

        <div class="Learnmore" @click="jumpToSelectedcases">
          了解更多服务案例
          <img src="../assets/more.png" alt class="rightArrow" />
        </div>
      </div>

      <!-- 新闻资讯 -->
      <div class="newsBOX">
        <h1 class="SolutionTitle">新闻资讯</h1>
        <div class="new-swiper">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide new-swiper-slide"
              v-for="(item,index) in newlist"
              :key="index"
              :data-link="item.link"
            >
              <div class="newImg" :data-link="item.link">
                <img :src="item.bgUrl" alt :data-link="item.link" />
              </div>
                <div class="purposeLine">
                  <div class="line" :data-link="item.link"></div>
                </div>
              <div class="contentArea" :data-link="item.link">
                <!-- <div class="time">
                    <div class="date" :data-link="item.link">{{ item.date }}</div>
                    <div class="yaer" :data-link="item.link">{{ item.yaer }}</div>
                </div> -->
                <div class="contet">
                  <div class="news-Title" :data-link="item.link">{{item.title}}</div>
                  <!-- <div class="news-Title" :data-link="item.link">{{item.subTitle}}</div> -->
                  <div class="content" :data-link="item.link">{{ item.briefIntroduction }}</div>
                </div>
              </div>
                <div class="time">{{ item.createdTime }}</div>
            </div>
          </div>
        </div>
        <div class="Learnmore" @click="jumpToNewList">
          了解更多新闻
          <img src="../assets/more.png" alt class="rightArrow" />
        </div>
        <div class="nextButton">
          <div class="newsSwiper-button-next"></div>
        </div>
        <div class="preButton">
          <div class="newsSwiper-button-prev"></div>
        </div>
      </div>

      <!-- 全服务生命周期 -->
      <div class="FullService">
        <video id="bgVid" playsinline :autoplay="true" :muted="true" :loop="true" class>
          <source src="../assets/star.mp4" type="video/mp4" />
        </video>
        <div class="FullServiceBox">
          <div class="FullServicefont1">全生命周期服务</div>
          <div class="FullServicefont2">以客户为中心，以结果为导向</div>
          <div class="FullServicefont2">提供数智化体验营销项目的咨询规划、</div>
          <div class="FullServicefont2">创意设计、建设交付和运营管理</div>
          <div class="FullServiceBtn" @click="toDetail">
            <img src="../assets/images/home/查看详情.png" alt />
          </div>
        </div>
      </div>

      <!-- 发现新ICT -->
      <div class="newIct" id="wrapper">
        <!-- 底图 -->
        <div class="bgimg">
          <img src="../assets/images/home/Image底.png" />
          <!-- 文字部分 -->
          <div class="bgtext">
            <!-- <div class="backgroundColor" v-if="isShowLogo" id="bgimg33333"></div> -->
            <div class="backgroundColor" id="bgimg33333"></div>
            <p class="first" id="bgfirst">发现新ICT</p>
            <p class="second" id="bgsecond">数智化体验营销服务商</p>
            <p class="small" id="bgsmall">一家专注于新ICT领域的数智营销服务机构</p>
            <p class="small" id="bgsmall1">通过将展示、营销和科技完美融合</p>
            <p class="small" id="bgsmall2">为新ICT关联企业提供数智化体验和营销服务</p>
            <p class="small" id="bgsmall3">促使数字技术更为高效快捷的融入政企机构</p>
            <p class="small" id="bgsmall3">为数字中国的发展建设提供助力</p>
          </div>
        </div>
        <div class="bgbtnGGG" @click="toAboutSidex" id="bgbtnGGG">
          <img src="../assets/images/home/查看详情.png" />
        </div>
        <!-- 覆盖部分 -->
        <img class="cover" src="../assets/images/home/形状结合(1).svg" id="cover" v-if="showCover" />
        <!-- 覆盖部分上的文字 -->
        <div class="cover-text">
          <p class="first" id="first">发现新ICT</p>
          <p class="second" id="second">体验千行百业数字化转型</p>
        </div>
      </div>
    </div>
    <!-- <topTop></topTop> -->
  </div>
</template>



<script type="text/javascript">
import Bottom from "@/components/bottom.vue";
import toTop from "@/components/toTop.vue";
import Swiper from "swiper";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { captureRejectionSymbol } from "events";
import $ from "jquery";
gsap.registerPlugin(ScrollTrigger);
let vm = null;
export default {
  created() {
    console.log("123123");
    vm = this;
  },

  data() {
    return {
      showCover: true,
      isShowLogo: false,
      jumpToSolution: [],
      jumpToCase: [],
      Industry: [],
      banner: [],
      showVideoPoup: false,
      videoUrl: "",
      isLoaded: false,

      newlist: [
        {
          id: 1,
          title: "新闻1",
          subTitle: "新闻福报提",
          briefIntroduction:
            "黄山移动作为黄山市网络规模、客户规模最大的通讯行业主导运营商，携手产业合作伙伴共同开拓5G“先行”之路，加快5G与云计算、大数据、区块链等新技术的深度融合，权利推动5G在旅游、香薰权利推动5G在旅游、香薰权利推动5G在旅游、香薰权利推动5G在旅游、香薰权利推动5G在旅游、香薰",
          link: "https://www.baidu.com",
          bgUrl:
            "http://cdn.sidex.cn/vert-static/f4e7746f-a987-4850-94f5-46edebc4a777.jpg"
        },
        {
          id: 1,
          title: "新闻1",
          subTitle: "新闻福报提",
          briefIntroduction:
            "黄山移动作为黄山市网络规模、客户规模最大的通讯行业主导运营商，携手产业合作伙伴共同开拓5G“先行”之路，加快5G与云计算、大数据、区块链等新技术的深度融合，权利推动5G在旅游、香薰权利推动5G在旅游、香薰权利推动5G在旅游、香薰权利推动5G在旅游、香薰权利推动5G在旅游、香薰",
          link: "https://www.baidu.com",
          bgUrl:
            "http://cdn.sidex.cn/vert-static/f4e7746f-a987-4850-94f5-46edebc4a777.jpg"
        },
        {
          id: 1,
          title: "新闻1",
          subTitle: "新闻福报提",
          briefIntroduction:
            "黄山移动作为黄山市网络规模、客户规模最大的通讯行业主导运营商，携手产业合作伙伴共同开拓5G“先行”之路，加快5G与云计算、大数据、区块链等新技术的深度融合，权利推动5G在旅游、香薰权利推动5G在旅游、香薰权利推动5G在旅游、香薰权利推动5G在旅游、香薰权利推动5G在旅游、香薰",
          link: "https://www.baidu.com",
          bgUrl:
            "http://cdn.sidex.cn/vert-static/f4e7746f-a987-4850-94f5-46edebc4a777.jpg"
        },
        {
          id: 1,
          title: "新闻1",
          subTitle: "新闻福报提",
          briefIntroduction:
            "黄山移动作为黄山市网络规模、客户规模最大的通讯行业主导运营商，携手产业合作伙伴共同开拓5G“先行”之路，加快5G与云计算、大数据、区块链等新技术的深度融合，权利推动5G在旅游、香薰权利推动5G在旅游、香薰权利推动5G在旅游、香薰权利推动5G在旅游、香薰权利推动5G在旅游、香薰",
          link: "https://www.baidu.com",
          bgUrl:
            "http://cdn.sidex.cn/vert-static/f4e7746f-a987-4850-94f5-46edebc4a777.jpg"
        },
        {
          id: 1,
          title: "新闻1",
          subTitle: "新闻福报提",
          briefIntroduction:
            "黄山移动作为黄山市网络规模、客户规模最大的通讯行业主导运营商，携手产业合作伙伴共同开拓5G“先行”之路，加快5G与云计算、大数据、区块链等新技术的深度融合，权利推动5G在旅游、香薰权利推动5G在旅游、香薰权利推动5G在旅游、香薰权利推动5G在旅游、香薰权利推动5G在旅游、香薰",
          link: "https://www.baidu.com",
          bgUrl:
            "http://cdn.sidex.cn/vert-static/f4e7746f-a987-4850-94f5-46edebc4a777.jpg"
        },
        {
          id: 1,
          title: "新闻1",
          subTitle: "新闻福报提",
          briefIntroduction:
            "黄山移动作为黄山市网络规模、客户规模最大的通讯行业主导运营商，携手产业合作伙伴共同开拓5G“先行”之路，加快5G与云计算、大数据、区块链等新技术的深度融合，权利推动5G在旅游、香薰权利推动5G在旅游、香薰权利推动5G在旅游、香薰权利推动5G在旅游、香薰权利推动5G在旅游、香薰",
          link: "https://www.baidu.com",
          bgUrl:
            "http://cdn.sidex.cn/vert-static/f4e7746f-a987-4850-94f5-46edebc4a777.jpg"
        }
      ]
    };
  },

  methods: {
    hide() {
      this.showVideoPoup = false;
    },
    playvideo(item) {
      console.log(item);
      this.showVideoPoup = true;
      console.log(item);
      this.videoUrl = item.videoUrl;
    },
    jumpToNewList() {
      let hycj = document.getElementById("hycj");
      let jjfa = document.getElementById("jjfa");
      let jxal = document.getElementById("jxal");
      let fwnl = document.getElementById("fwnl");
      let gysd = document.getElementById("gysd");
      let jrwm = document.getElementById("jrwm");
      let home = document.getElementById("home");
      let xwzx = document.getElementById("xwzx");
      xwzx.style.fontFamily = "SC_Bold";
      home.style.fontFamily = "SC_Light";
      hycj.style.fontFamily = "SC_Light";
      jjfa.style.fontFamily = "SC_Light";
      jxal.style.fontFamily = "SC_Light";
      fwnl.style.fontFamily = "SC_Light";
      gysd.style.fontFamily = "SC_Light";
      jrwm.style.fontFamily = "SC_Light";
      if (this.$route.path !== "/newsList") {
        this.$router.push({ name: "newsList" });
      }
    },
    toAboutSidex() {
      let hycj = document.getElementById("hycj");
      let jjfa = document.getElementById("jjfa");
      let jxal = document.getElementById("jxal");
      let fwnl = document.getElementById("fwnl");
      let gysd = document.getElementById("gysd");
      let jrwm = document.getElementById("jrwm");
      let home = document.getElementById("home");
      let xwzx = document.getElementById("xwzx");
      xwzx.style.fontFamily = "SC_Light";
      home.style.fontFamily = "SC_Light";
      hycj.style.fontFamily = "SC_Light";
      jjfa.style.fontFamily = "SC_Light";
      jxal.style.fontFamily = "SC_Light";
      fwnl.style.fontFamily = "SC_Light";
      gysd.style.fontFamily = "SC_Bold";
      jrwm.style.fontFamily = "SC_Light";
      this.$router.push("/aboutSidex");
    },
    toDetail() {
      let hycj = document.getElementById("hycj");
      let jjfa = document.getElementById("jjfa");
      let jxal = document.getElementById("jxal");
      let fwnl = document.getElementById("fwnl");
      let gysd = document.getElementById("gysd");
      let jrwm = document.getElementById("jrwm");
      let home = document.getElementById("home");
      let xwzx = document.getElementById("xwzx");
      xwzx.style.fontFamily = "SC_Light";
      home.style.fontFamily = "SC_Light";
      hycj.style.fontFamily = "SC_Light";
      jjfa.style.fontFamily = "SC_Light";
      jxal.style.fontFamily = "SC_Light";
      fwnl.style.fontFamily = "SC_Bold";
      gysd.style.fontFamily = "SC_Light";
      jrwm.style.fontFamily = "SC_Light";
      this.$router.push("/Servicecapability");
    },
    handleClickSlide(id) {
      // const index = vm.clickedIndex - vm.activeIndex + vm.realIndex === 7 ? 0 : vm.clickedIndex - vm.activeIndex + vm.realIndex
      // 我得到的index就是点击的item在实际数组中的下标index

      this.IndustryScenario(id);
      // 后面可以根据得到的index判断跳到每个对应的路由this.$router.push({path: `/single`});
    },

    toNewDetail(link) {
      console.log(link);
      window.open(link);
    },
    //跳转到案例案例大全页面/Selectedcases
    jumpToSelectedcases() {
      this.$router.push("/Selectedcases");
    },
    //跳转到精选案例case页面
    jumpToCaseEvent(id) {
      console.log("跳转到", id);
      if (this.$route.path !== "/case") {
        this.$router.push({
          name: "case",
          params: { id }
        });
      }
    },
    jumpToSolutionEvne(id) {
      // console.log(item)

      let hycj = document.getElementById("hycj");
      let jjfa = document.getElementById("jjfa");
      let jxal = document.getElementById("jxal");
      let fwnl = document.getElementById("fwnl");
      let gysd = document.getElementById("gysd");
      let jrwm = document.getElementById("jrwm");
      let home = document.getElementById("home");
      let xwzx = document.getElementById("xwzx");
      xwzx.style.fontFamily = "SC_Light";
      home.style.fontFamily = "SC_Light";
      hycj.style.fontFamily = "SC_Light";
      jjfa.style.fontFamily = "SC_Bold";
      jxal.style.fontFamily = "SC_Light";
      fwnl.style.fontFamily = "SC_Light";
      gysd.style.fontFamily = "SC_Light";
      jrwm.style.fontFamily = "SC_Light";
      if (this.$route.path !== "/Solution") {
        this.$router.push({ name: "Solution", params: { id } });
      }
    },
    jumpToSoluitionDaday() {
      let hycj = document.getElementById("hycj");
      let jjfa = document.getElementById("jjfa");
      let jxal = document.getElementById("jxal");
      let fwnl = document.getElementById("fwnl");
      let gysd = document.getElementById("gysd");
      let jrwm = document.getElementById("jrwm");
      let home = document.getElementById("home");
      let xwzx = document.getElementById("xwzx");
      xwzx.style.fontFamily = "SC_Light";
      home.style.fontFamily = "SC_Light";
      hycj.style.fontFamily = "SC_Light";
      jjfa.style.fontFamily = "SC_Bold";
      jxal.style.fontFamily = "SC_Light";
      fwnl.style.fontFamily = "SC_Light";
      gysd.style.fontFamily = "SC_Light";
      jrwm.style.fontFamily = "SC_Light";
      if (this.$route.path !== "/SoluitionDaday") {
        this.$router.push({ name: "SoluitionDaday" });
      }
    },
    //跳转到子路由
    jumpToSoluitionChild(item) {
      console.log(item);
      this.$router.push({
        path: "/SoluitionChild",
        query: {
          name: item
        }
      });
    },
    IndustryScenario(id) {
      console.log(id);
      this.$router.push({
        name: "IndustryScenario",
        params: { id }
      });
    },
    Solution() {
      this.$router.push("/Solution");
    },
    Servicecapability() {
      this.$router.push("/Servicecapability");
    },
    aboutSidex() {
      this.$router.push("/aboutSidex");
    },
    Selectedcases() {
      this.$router.push("/Selectedcases");
    },
    joinour() {
      this.$router.push("/JoinUS");
    }

    // handleScroll() {
    //   let scrollTop =
    //     window.pageYOffset ||
    //     document.documentElement.scrollTop ||
    //     document.body.scrollTop;
    //     console.log('123123123123123123123123123',scrollTop)
    //   let bgimg = document.getElementById("bgimg");
    //   if (scrollTop > 9293.93) {
    //     // console.log("给我他妈出现!!", bgimg);
    //     this.isShowLogo = true;
    //   } else if (scrollTop < 9281) {
    //     // console.log("geiwotama1消失!");
    //     this.isShowLogo = false;
    //   }
    // }
  },
  components: {
    Bottom,
    toTop
  },
  updated() {
    //行业轮播图
    var video = document.querySelector("video");
    console.log(video);
    video.setAttribute("autoplay", "true");
  },

  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    console.log(this.$route.path);

    this.$axios("news/list?count=10").then(res => {
      console.log("新闻资讯", res);
      res.data.data.forEach(item=>{
        // item.date=item.createdTime.substring(5, 10);
        // item.yaer=item.createdTime.substring(0, 4);
        // item.date= item.date.replace("-","/")
        item.createdTime=item.createdTime.substring(0, 10);
      })
      this.newlist = res.data.data;
      console.log('123231233this.newlist',this.newlist);

      this.$nextTick(() => {
        new Swiper(".new-swiper", {
          slidesPerView: 3,
          spaceBetween: 14,
          navigation: {
            nextEl: ".nextButton",
            prevEl: ".preButton"
          },
          on: {
            click: function(e) {
              console.log(e.target.dataset);
              vm.toNewDetail(e.target.dataset.link);
            }
          }
        });
      });
    });

    // 轮播图
    this.$axios("getBanner").then(res => {
      console.log("轮播图", res);
      this.banner = res.data.data;
      this.$nextTick(() => {
        new Swiper(".swiper1", {
          loop: false, // 循环模式选项
          // 如果需要分页器
          pagination: {
            el: ".pagination1",

            clickable: true
          },
          observer: true,
          observeParents: true
          // navigation: {
          //   nextEl: ".swiper-button-next",
          //   prevEl: ".swiper-button-prev"
          // }
          // 如果需要滚动条
        });
      });
    });

    this.$axios.get("getIndustry").then(res => {
      // this.Industry=res.data.data

      this.Industry = res.data.data;
      console.log(this.Industry);
      this.$nextTick(() => {
        new Swiper(".swiper2", {
          slidesPerView: 5,
          loop: false,
          observer: true,
          freeMode: true,
          on: {
            click: function(e) {
              console.log(e.target.dataset);
              vm.handleClickSlide(e.target.dataset.id);
            }
          },
          navigation: {
            nextEl: ".swiper2-button-next",
            prevEl: ".swiper2-button-prev"
          }
        });
      });
      console.log("getIndustry", this.Industry);
    });

    //行业轮播图

    //轮播图

    this.$axios.get("getBanner").then(res => {
      console.log("getBanner", res);
    });

    //首页解决方案 默认12
    this.$axios.get("getSolutionByHomePage").then(res => {
      res.data.data.forEach(value => {
        const val = JSON.parse(value.subTitle);
        value.smallTitle = val.smallTitle;
        value.subTitle = val.subTitle;
      });

      this.jumpToSolution = res.data.data;
      console.log(" this.jumpToSolution", this.jumpToSolution);
      this.$nextTick(() => {
        gsap.from("#item_block_1", {
          scrollTrigger: "#item_block_1",
          duration: 2,
          opacity: 0,
          y: 200
        });
        gsap.from("#item_block_2", {
          scrollTrigger: "#item_block_2",
          duration: 1,
          opacity: 0,
          y: 200
        });
        gsap.from("#item_block_3", {
          scrollTrigger: "#item_block_3",
          duration: 2.2,
          opacity: 0,
          y: 200
        });
        gsap.from("#item_block_4", {
          scrollTrigger: "#item_block_4",
          duration: 1,
          opacity: 0,
          y: 200
        });

        gsap.from("#item_block_5", {
          scrollTrigger: "#item_block_5",
          duration: 2,
          opacity: 0,
          y: 200
        });
        gsap.from("#item_block_6", {
          scrollTrigger: "#item_block_6",
          duration: 1,
          opacity: 0,
          y: 200
        });
        gsap.from("#item_block_7", {
          scrollTrigger: "#item_block_7",
          duration: 2.2,
          opacity: 0,
          y: 200
        });
        gsap.from("#item_block_8", {
          scrollTrigger: "#item_block_8",
          duration: 1,
          opacity: 0,
          y: 200
        });
        gsap.from("#item_block_9", {
          scrollTrigger: "#item_block_9",
          duration: 2,
          opacity: 0,
          y: 200
        });
        gsap.from("#item_block_10", {
          scrollTrigger: "#item_block_10",
          duration: 1,
          opacity: 0,
          y: 200
        });
        gsap.from("#item_block_11", {
          scrollTrigger: "#item_block_11",
          duration: 2.2,
          opacity: 0,
          y: 200
        });
        gsap.from("#item_block_12", {
          scrollTrigger: "#item_block_12",
          duration: 1,
          opacity: 0,
          y: 200
        });
      });
    });

    // 首页精选案例 默认12条  getCaseByHomePage
    this.$axios.get("getCaseByHomePage").then(res => {
      // res.data.data.forEach(value =>{
      //       const val = JSON.parse(value.subTitle)
      //       value.smallTitle = val.smallTitle
      //       value.subTitle = val.subTitle
      //   })
      this.jumpToCase = res.data.data;
      console.log(" 123123123this.jumpToCase", this.jumpToCase);
    });

    this.$nextTick(() => {
      gsap.from("#cover", {
        scrollTrigger: {
          trigger: "#wrapper",
          pin: true,
          start: "top",
          end: "bottom",
          // opacity:1,
          // transform: scale(1)
          onToggle: self => {
            console.log(self.isActive);
          },
          onUpdate: self => {
            if (self.progress == 0) {
              this.showCover = false;
            } else {
              this.showCover = true;
            }
            let coverImage = document.getElementById("cover");
            let first = document.getElementById("first");
            let second = document.getElementById("second");
            let bgfirst = document.getElementById("bgfirst");
            let bgsecond = document.getElementById("bgsecond");
            let bgsmall = document.getElementById("bgsmall");
            let bgsmall1 = document.getElementById("bgsmall1");
            let bgsmall2 = document.getElementById("bgsmall2");
            let bgsmall3 = document.getElementById("bgsmall3");
            let bgbtnGGG = document.getElementById("bgbtnGGG");
            let bgimg33333 = document.getElementById("bgimg33333");
            bgbtnGGG.style.opacity = `${1 - self.progress * 1.5}`;
            // bgbtnGGG.style.opacity = 0;
            // console.log('在这',first.style.opacity)
            first.style.opacity = `${self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress}`;
            second.style.opacity = `${self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress}`;
            bgfirst.style.opacity = `${1 -
              self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress}`;
            bgsecond.style.opacity = `${1 -
              self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress}`;
            bgsmall.style.opacity = `${1 -
              self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress}`;
            bgsmall1.style.opacity = `${1 -
              self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress}`;
            bgsmall2.style.opacity = `${1 -
              self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress}`;
            bgsmall3.style.opacity = `${1 -
              self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress *
                self.progress}`;

            // if (self.progress > 0.999) {
            //   this.isShowLogo = true;
            // } else if (self.progress < 0.999) {
            //   this.isShowLogo = false;
            // }
            bgimg33333.style.opacity = `${self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress *
              self.progress}`;
            console.log("123123", bgimg33333.style.opacity);
            // coverImage.style.transform = `scale(${45.5 -
            //   self.progress * 45.5 +
            //   1})`;
            coverImage.style.transform = `scale(${100.5 -
              self.progress * 100.5 +
              1})`;
            // coverImage.style.marginTop = `${249 - self.progress * 249 + "vw"}`;
            coverImage.style.marginTop = `${1230 -
              self.progress * 1230 +
              "vw"}`;
            // coverImage.style.marginLeft = `${40 - self.progress * 40 + "vw"}`;
            coverImage.style.marginLeft = `${60 - self.progress * 60 + "vw"}`;

            // console.log(self.progress);
            // console.log(first.style.opacity);
            // console.log(coverImage.style.marginTop);
            // console.log(coverImage.style.transform);
          }
        }
      });
    });
    // gsap.to("#first", {
    //   scrollTrigger: {
    //     trigger: "#wrapper",
    //     pin: true,
    //     start: "top",
    //     end: "bottom",
    //     onUpdate: self => {
    //       console.log("progress:", self.progress)
    //       let first = document.getElementById('first')
    //       first.style.opacity =0
    //       // console.log(coverImage.style.marginTop)
    //       // console.log(coverImage.style.width )
    //     }
    //   }, // 此行代码表示触发动画的元素，只需要增加该行代码，就可以实现想要的效果。
    // });
  }
};
</script>

<style scoped>
@import url("../../node_modules/swiper/css/swiper.css");

.body {
  width: 100%;
  /* overflow: hidden; */
  position: relative;
}

.homeTop {
  width: 100%;
  height: 65px;
  font-family: "SC_Light";
  /* background-color: #6461a6; */
  background: rgba(100, 97, 166, 0.6);
  position: fixed;
  display: flex;
  justify-content: space-between;
  z-index: 999;
}

.homeTop_logo {
  width: 123px;
  height: 45px;
  background-image: url("../assets/logo.svg");
  background-size: 100% 100%;
  margin-left: 40px;
  margin-top: 10px;
}

.homeTop_btn {
  height: 65px;
  margin-top: 1.6px;
  margin-right: 40px;
  display: flex;
  justify-content: space-between;
  line-height: 65px;
  font-size: 18px;
  color: #fff;
}

.boys {
  background-color: #6461a6;
  margin: 0;
  padding: 0;
  display: none;
}

.btn_center {
  font-size: 8px;
  text-align: center;
  border-bottom: 0.5px solid #fff;
  font-family: "SC_Thin";
}

.btn {
  text-align: center;
  margin-right: 10px;
}

.btn-font {
  display: flex;
  text-align: right;
  justify-content: space-between;
  font-size: 14px;
}

.btn:hover {
  border-bottom: 2px solid #fff;
  font-weight: 600;
}

.btn-font::after {
  display: block;
  content: "";
  width: 11px;
  height: 6px;
  background-image: url("../assets/资源\ 22.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin: 29.5px 3px 0 3px;
}

.btn_center:hover {
  font-family: "SC_Bold";
}

.btn:hover .boys {
  display: block;
}

.btn-font2 {
  text-align: center;
  font-size: 14px;
}

.swiper-container {
  /* margin-top: 64px; */
  height: 964px;
  width: 100vw;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
}

.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 32px;
  left: 0;
  /* height: 100px; */
  width: 100%;
}

/deep/.swiper-pagination-bullet {
  width: 10px !important;
  height: 10px !important;
  display: inline-block;
  border-radius: 100%;
  background: #999;
  opacity: 0.2;
}

/deep/.swiper-pagination-bullet-active {
  opacity: 1;
  background: #fff;
  /* box-shadow: 0 0 20px 4px #999; */
  border: 2px solid #999;
}

/deep/.swiper-container-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: 0 10px;
}

.Industryscenario {
  width: 100%;
  background-color: #ffff;
}

.IndustryscenarioTitle {
  overflow: hidden;
  text-align: center;
  line-height: 30px;
  letter-spacing: 3px;
  font-size: 30px;
  color: #333333;
  padding-top: 100px;
  padding-bottom: 80px;
  font-family: "SC_Bold";
}

.swiper2 {
  width: 65%;
  height: 294px;
  overflow: hidden;
  --swiper-theme-color: red;
}

.swiper-slide {
  /* text-align: center; */
  font-size: 13px;
  /* color: #fff; */
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.text-group {
  position: absolute;
  left: 10%;
  bottom: 36%;
}
.text-group .title {
  font-family: "SC_Bold";
  font-size: 70px;
  margin: 0;
  color: #fff;
}
.text-group .content {
  font-family: "SC_Medium";
  font-size: 47px;
  margin: 0;
  margin-bottom: 20px;
  color: #fff;
}
.text-group .button {
  width: 125px;
  height: 35px;

  border-radius: 15px;
  /* margin-left: 20px; */
}
.slide2 {
  overflow: hidden;
}

.inner {
  width: 100%;
  height: 100%;
  color: #999;
}

.borderpic {
  width: 190px;
  height: 190px;
  margin: 0 auto;
  border-radius: 50%;
  transition: 0.5s;
}

.borderpic img {
  width: 190px;
  height: 190px;
  object-fit: cover;
  border-radius: 50%;
  margin: 0 auto;
  transition: 0.5s;
}
.inner:hover .borderpic {
  width: 230px;
  height: 230px;
}
.inner:hover .borderpic img {
  width: 230px;
  height: 230px;
}

.border-font {
  width: 70%;
  text-align: center;
  /* line-height: 30px; */
  font-size: 16px;
  margin: 29px auto 0;
}

.swiper2-button-prev {
  width: 43.7px;
  height: 45px;
  background: url("../assets/next.png") no-repeat;
  background-size: 100% 100%;
  top: 80px;
  left: 248px;
  position: relative;
  outline: none;
}

.swiper2-button-next {
  outline: none;
  width: 43.7px;
  height: 45px;
  background: url("../assets/prev.png") no-repeat;
  border-radius: 50%;
  background-size: 100% 100%;
  top: 80px;
  right: 248px;
  position: relative;
}

.swiperBox {
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
}
</style>

<style scoped>
.Solution {
  width: 100%;
  background-color: #f7f7f7;
  border-bottom: 1px solid #f7f7f7;
  /* padding-bottom: 50px; */
}

.SolutionTitle {
  width: 100%;
  overflow: hidden;
  text-align: center;
  line-height: 30px;
  letter-spacing: 3px;
  font-size: 30px;
  color: #333333;
  padding-top: 100px;
  padding-bottom: 80px;
  font-family: "SC_Bold";
}

/* .solutioncenter {
  width: 448px;
  height: 430px;
  background-color: #fff;
  margin-bottom: 80px;
} */

.solutionpic {
  width: 100%;
}

.solutionpic img {
  width: 100%;
  height: 100%;
  display: block;
  /* margin-bottom: 12px; */
}

.solutionIntroduce {
  font-size: 20px;
  line-height: 20px;
  color: #333333;
  font-family: "SC_Medium";
  margin-bottom: 10px;
  margin-top: 20px;
  margin-left: 20px;
  white-space: pre;
}

.solutionName {
  font-size: 14px;
  color: #333333;
  white-space: pre;
  line-height: 14px;
  margin-bottom: 10px;
  margin-top: 10px;
  margin-left: 20px;
}

.selectedCases {
  width: 100%;
  overflow: hidden;
}

.selectedCasesBox {
  width: 1830px; /*1804px; */
  height: 1990px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.selectedCasescenter {
  width: 601.333px;
  overflow: hidden;
  /* height: 9.375px; */
  background-color: #fff;
  /* margin-top: 20px; */
  /* min-height:7.375px;
  max-height: 9.375px; */
  transition: all 0.4s;
  margin-bottom: 40px;
  /* border: 2px solid black; */
  height: 450.333px;
}
.selectedCasescenter:hover {
  box-shadow: 0 8px 8px 0 #f2f2f2;
  /* transform: translate(0, -10px);  */
}
.selectedCasespic {
  height: 80%;
  width: 100%;
  border-radius: 6px;
  overflow: hidden;
}

.selectedCasespic img {
  width: 100%;
  height: 100%;
}

.selectedCasesIntroduce {
  font-size: 20px;
  /* line-height: 20px; */
  color: #333333;
  font-family: "SC_Medium";
  margin-bottom: 10px;
  margin-top: 20px;
  margin-left: 20px;
}

.selectedCasesName {
  font-size: 14px;
  color: #333333;
  line-height: 14px;
  margin-bottom: 10px;
  margin-top: 10px;
  margin-left: 20px;
}

.Learnmore {
  text-align: center;
  font-size: 14px;
  color: #333333;
  letter-spacing: 2px;
  margin-top: 30px;
  margin-bottom: 100px;
  position: relative;
}
.Learnmore img {
  /* display: block;
  position: absolute;
  right: 30%; */
}
.FullService {
  width: 100%;
  height: 1000px;
  /* background-image: url(../assets/FullService.png); */
  /* background-color: red; */
  /* background-size: 100% 100%; */
  color: #fff;
  text-align: center;
  background-repeat: no-repeat;
  position: relative;
}
#bgVid {
  width: 100%;
  position: relative;
  z-index: -100;
}
.FullServiceBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.FullServicefont1 {
  margin-bottom: 20px;
  font-size: 60px;
  letter-spacing: 1px;
  font-family: "SC_Bold";
}

.FullServicefont2 {
  margin-bottom: 5px;
  font-size: 36px;
  letter-spacing: 1px;
  color: #ffffff;
  line-height: 36px;
  margin-top: 20px;
}
.FullServicefont3 {
  margin-bottom: 5px;
  font-size: 46px;
  letter-spacing: 1px;
  line-height: 46px;
  margin-top: 20px;
  color: #424242;
}
.FullServiceBtn {
  width: 151.9px;
  height: 40.3px;
  font-size: 20px;

  margin: 0 auto;
  border-radius: 20px;
  letter-spacing: 1px;
  margin-top: 60px;

  cursor: pointer;
}
.FullServiceBtn img {
  width: 100%;
}
.newIct {
  /* width: 100%; */
  height: 940px;
  /* position: relative; */
  /* background-size: 100% 100%; */
  text-align: center;
  /* overflow: hidden; */
  /* background-repeat: no-repeat; */
}
.backgroundColor {
  position: absolute;
  width: 11118px;
  height: 1008px;
  /* background-image: url(../assets/images/home/show.png); */
  background-color: #64619e;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  top: -212px;
  left: -650px;
  /* top: 20.9%;
  left: 44.49%; */
  z-index: 1;
  /* animation-name: showSlow;
  animation-duration: 2s;
  animation-fill-mode: forwards; */
  opacity: 0;
}
@keyframes showSlow {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
    animation-fill-mode: forwards;
  }
}
.bgimg {
  /* background-image: url(../assets/images/home/Image底.png); */
  /* background-repeat: no-repeat; */
  height: 100%;
  /* background-color: red; */
  position: relative;
}
.bgimg img {
  width: 100%;
  height: 100%;
}
.bgimg > .bgtext {
  height: 450px;
  width: 450px;
  /* background-color: red; */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-top: 101px;
}
.bgimg > .bgtext > .first {
  opacity: 1;
  color: #ffffff;
  font-size: 60px;
  margin: 0;
  margin-bottom: 5px;
  font-family: "SC_Medium";
}
.bgimg > .bgtext > .second {
  opacity: 1;
  color: #ffffff;
  font-size: 28px;
  margin: 0;
  margin-bottom: 36px;
  font-family: "SC_Medium";
}
.bgimg > .bgtext > .small {
  opacity: 1;
  color: #ffffff;
  font-size: 16px;
  line-height: 29px;
  margin: 0;
  font-family: "SC_Thin";
}
.bgbtnGGG {
  /* background-color: red; */
  width: 100px;
  left: 50%;
  top: 68%;

  position: absolute;
  z-index: 10000;
  transform: translate(-50%, -50%);
  /* opacity: 0; */
  opacity: 1;
}
.bgbtnGGG img {
  width: 100%;
}
.cover {
  /* background-color: red */
  transform: scale(1);
  position: absolute;
  top: 0;
  left: 0;
  /* height: 100%; */
  width: 100%;
  /* z-index: -1; */
}
.cover-text {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.cover-text > .first {
  font-size: 70px;
  color: #333333;
  margin-bottom: 0px;
  font-family: "SC_Bold";
  opacity: 0;
}
.cover-text > .second {
  font-size: 46px;
  color: #333333;
  font-family: "SC_Medium";
  margin-top: 20px;
  opacity: 0;
}
</style>

<style scoped>
/*动画加载开始*/
.SolutionBox {
  width: 1830px;
  height: 1490px;
  margin: 0 45px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
}

.solutioncenter {
  width: 448px;
  height: 430px;
  padding-bottom: 20px;
  background-color: #fff;
  /* transform: translateY(120px); */
}
.solutioncenter2 {
  width: 448px;
  height: 430px;
  background-color: #fff;
}
.solutioncenter3 {
  width: 448px;
  height: 430px;
  background-color: #fff;
}

/*动画加载结束*/

/* END */

/* 下拉菜单与鼠标移入 */

.cover {
  /* margin-left: 100vw; */
  margin-top: 359vw;
}
#bgfirst {
  opacity: 1;
}
.Learnmore .rightArrow {
  position: relative;
  top: 3px;
  width: 20px;
  height: 15px;
}
.video-js {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.yesBlack {
  width: 100%;
  height: 100%;
  object-fit: fill;
}
.videoPoup {
  width: 1920px;
  height: 100vh;
  position: fixed;
  background-color: #fff;
  z-index: 1000;
}
.videoPoup .cancel {
  width: 20px;
  position: absolute;
  /* background-color: #fff; */
  z-index: 1000;
  right: 20px;
}
.videoPoup .cancel img {
  width: 100%;
}
/* .my-bullet{
    width: 100px;
} */
.pagination1 >>> .swiper-pagination-bullet {
  /* width: 65px !important;
  height: 3px !important;
  border-radius: 0px;
  
  border: none;
  opacity: 1; */
  background-color: transparent;
  border: 2px solid #fff;
  opacity: 1;
}
.pagination1 >>> .swiper-pagination-bullet-active {
  /* width: 65px !important;
  height: 3px !important;
  border-radius: 0px;
  background: #fff;
  border: none; */

  background-color: #fff;
}
.swiper-button-prev {
  left: 80px !important;

  width: 80.7px;
  height: 95px;
  background: url("../assets/pre.png") no-repeat;
  background-size: 100% 100%;
}

.swiper-button-next {
  right: 80px !important;
  outline: none;
  width: 80.7px;
  height: 95px;
  background: url("../assets/nex.png") no-repeat;
  border-radius: 50%;
  background-size: 100% 100%;
}

.swiper-button-prev:after {
  content: "";
}
.swiper-button-next:after {
  content: "";
}

/* .swiper-button-prev {
  width: 43.7px;
  height: 45px;
  background: url("../assets/next.png") no-repeat;
  background-size: 100% 100%;
  top: 80px;
  left: 248px;
  position: relative;
  outline: none;
}

.swiper-button-next {
  outline: none;
  width: 43.7px;
  height: 45px;
  background: url("../assets/prev.png") no-repeat;
  border-radius: 50%;
  background-size: 100% 100%;
  top: 80px;
  right: 248px;
  position: relative;
}  */

.newsBOX {
  background-color: #f7f7f7;
  margin-bottom: 50px;
  position: relative;
  height: 950px;
  padding-top: 50px;
}
.newTitle {
  text-align: center;
}
.new-swiper {
  width: 1517px;
  margin: 0 auto;
  overflow: hidden;
}
.swiper-slide {
  /* margin-right: 50px; */
  /* background-color: red; */
}
.new-swiper-slide {
  width: 496px;
  height: 510px;
  display: block !important;
  background-color: #fff;
  margin-bottom: 10px;
}
.newImg {
  height: 350px;
  position: relative;
  overflow: hidden;

}
.newImg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition:  1.6s cubic-bezier(0.23, 1, 0.38, 1) 0s;
}
.contentArea {
  margin-left: 10px;
  margin-top: 17px;
  margin-bottom: 13px;
  display: flex;
}
.contentArea div {
  /* margin-bottom: 7px; */
}
.contentArea .news-Title {
  font-family: "SC_Medium";
  font-size: 20px;
  width: 460px;
  overflow: hidden;
  white-space: nowrap;
text-overflow:ellipsis;
}
.contentArea .content {
  font-family: "SC_Light";
  font-size: 10px;
  width: 454px;
  margin: 0 auto;
  margin-left: 2px;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin-top: 13px;
  line-height: 15px;
}
.nextButton {
  background-color: #fff;
  height: 44.49px;
  width: 43.15px;
  position: absolute;
  right: 130px;
  bottom: 190px;
}
.preButton {
  background-color: #fff;
  height: 44.49px;
  width: 43.15px;
  position: absolute;
  position: absolute;
  right: 130px;
  bottom: 250px;
}
.newsSwiper-button-next {
  outline: none;
  width: 13px;
  height: 17px;
  background: url("../assets/prev.png") no-repeat;
  /* border-radius: 50%; */
  background-size: 100% 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.newsSwiper-button-prev {
  width: 13px;
  height: 17px;
  background: url("../assets/next.png") no-repeat;
  background-size: 100% 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;
}
.new-swiper-slide:hover .line {
  width: 100%;

}
.new-swiper-slide:hover .newImg img {
  transform: scale(1.1);
}
.purposeLine {
  width: 100%;
}
.line {
  width: 0;
  height: 6px;
  background-color: #6461a6;
  transition: width 1.6s cubic-bezier(0.23, 1, 0.38, 1) 0s;
}
.contet{
  /* border-left: 1px solid rgb(0,0,0,0.2); */
  /* padding-left: 18px; */
  margin-left: 13px;
}
.time{
  margin-top: 14px;
  font-size: 14px;
text-align: right;
width: 480px;

  font-family: "SC_light";
}
.yaer{
  letter-spacing:3px;
  margin-left: 4px;
}
</style>