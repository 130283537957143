<template>
  <div class="body">
    <div class="center">
      <div class="picture">
        <div class="serviceBox">
          <div class="servicetitle">发现新ICT</div>
          <div class="servicefont1">数智化体验营销服务商</div>
          <div class="servicefont">思德科技是一家专注于ICT行业的展览展示服务企业，</div>
          <div class="servicefont">致力于体验营销的数字化、互联网化、智能化</div>
          <div class="servicefont">基于5G、云、大数据、AIoT、AR/VR/MR等信息技术，</div>
          <div class="servicefont">融合虚拟与实体场景的在线化交互体验，驱动产业政策、技术、</div>
          <div class="servicefont">产品和成果的互联互通。为政府及千行百业数字化转型的展示体验项目</div>
          <div class="servicefont">提供咨询规划、建设交付、数据展示、场景开发、</div>
          <div class="servicefont">智能升级和运营管理的全生命周期服务，</div>
          <div class="servicefont">助力政企客户在万物互联的智能时代转型升级。</div>
          <!-- <div class="servicefont">项目交付、业务包装、智能技术和运营管理的全生命周期服务，助力政企客</div>
          <div class="servicefont">户在万物互联的智能时代转型升级。</div>-->
        </div>
      </div>
      <div class="aboutSidextitle">
        <div class="aboutSidexfont1">愿景</div>
        <div class="aboutSidexfont2">全球ICT体验营销领跑者</div>
      </div>
      <div class="aboutSidextitle">
        <div class="aboutSidexfont1">使命</div>
        <div class="aboutSidexfont2">开启万物互联的智能世界</div>
      </div>

      <!-- <div class="aboutSidextitle">
    
        <div class="SenseOfWorth">
          <div
            class="SenseOfWorth1"
            ref="SenseOfWorth1"
            @mouseenter="onEnterT1"
            @mouseleave="onLeaveTd1"
          >
            <div class="SenseOfWorthslog" v-show="SenseOfWorth1">
              <div>CREATE INFINTE</div>
              <div>POSSIBILITES</div>
              <div>创造无限可能</div>
            </div>
          </div>
          <div
            class="SenseOfWorth2"
            ref="SenseOfWorth2"
            @mouseenter="onEnterT2"
            @mouseleave="onLeaveTd2"
          >
            <div class="SenseOfWorthslog" v-show="SenseOfWorth2">
              <div>KEEP IN</div>
              <div>SYNC</div>
              <div>始终同步 更进一步</div>
            </div>
          </div>
          <div
            class="SenseOfWorth3"
            ref="SenseOfWorth3"
            @mouseenter="onEnterT3"
            @mouseleave="onLeaveTd3"
          >
            <div class="SenseOfWorthslog" v-show="SenseOfWorth3">
              <div>TEAMWORK IS</div>
              <div>IMMINENT</div>
              <div>群策群力 一触即发</div>
            </div>
          </div>
          <div
            class="SenseOfWorth4"
            ref="SenseOfWorth4"
            @mouseenter="onEnterT4"
            @mouseleave="onLeaveTd4"
          >
            <div class="SenseOfWorthslog" v-show="SenseOfWorth4">
              <div>CRAFTSMAN</div>
              <div>SPIRIT</div>
              <div>工匠精神</div>
            </div>
          </div>
          <div
            class="SenseOfWorth5"
            ref="SenseOfWorth5"
            @mouseenter="onEnterT5"
            @mouseleave="onLeaveTd5"
          >
            <div class="SenseOfWorthslog" v-show="SenseOfWorth5">
              <div>LONG-</div>
              <div>TERMISM</div>
              <div>长期主义</div>
            </div>
          </div>
        </div>
      </div>-->

      <!-- <div class="aboutSidextitle">
        <div class="aboutSidexfont1">价值观</div>
        <div class="SenseOfWorth">
          <div
            class="SenseOfWorth1"
            ref="SenseOfWorth1"
       
           >
            <div class="SenseOfWorthslog" v-show="SenseOfWorth1">
              <div>CREATE INFINTE</div>
              <div>POSSIBILITES</div>
              <div>创造无限可能</div>
            </div>
          </div>
          <div
            class="SenseOfWorth2"
            ref="SenseOfWorth2"
    
          >
            <div class="SenseOfWorthslog" v-show="SenseOfWorth2">
              <div>KEEP IN</div>
              <div>SYNC</div>
              <div>始终同步 更进一步</div>
            </div>
          </div>
          <div
            class="SenseOfWorth3"
            ref="SenseOfWorth3"
   
          >
            <div class="SenseOfWorthslog" v-show="SenseOfWorth3">
              <div>TEAMWORK IS</div>
              <div>IMMINENT</div>
              <div>群策群力 一触即发</div>
            </div>
          </div>
          <div
            class="SenseOfWorth4"
            ref="SenseOfWorth4"
        
          >
            <div class="SenseOfWorthslog" v-show="SenseOfWorth4">
              <div>CRAFTSMAN</div>
              <div>SPIRIT</div>
              <div>工匠精神</div>
            </div>
          </div>
          <div
            class="SenseOfWorth5"
            ref="SenseOfWorth5"
         
          >
            <div class="SenseOfWorthslog" v-show="SenseOfWorth5">
              <div>LONG-</div>
              <div>TERMISM</div>
              <div>长期主义</div>
            </div>
          </div>
        </div>
      </div>-->

      <div class="aboutSidexfont1">价值观</div>
      <div class="box">
        <!-- 第一版本，手风琴 -->
        <!-- <ul>
          <li class="aaaa"     @mouseenter="onEnterT1"  style="  box-shadow:  0 0 0 0 rgb(0, 0, 0,0.5);"   @mouseleave="onLeaveTd1">
            <div class="title">
              <div>CREATE INFINTE</div>
              <div>POSSIBILITES</div>
              <div>创造无限可能</div>
            </div>
            <div class="image" >
              <img src="../assets/SenseOfWorth1.png" alt />
            </div>
          </li>
          <li class="aaaa"     @mouseenter="onEnterT1"    @mouseleave="onLeaveTd1">
            <div class="title">
              <div>KEEP IN</div>
              <div>SYNC</div>
              <div>始终同步 更进一步</div>
            </div>
            <div class="image">
              <img src="../assets/SenseOfWorth2.png" alt />
            </div>
          </li>
          <li class="aaaa"     @mouseenter="onEnterT1"    @mouseleave="onLeaveTd1">
            <div class="title">
              <div>TEAMWORK IS</div>
              <div>IMMINENT</div>
              <div>群策群力 一触即发</div>
            </div>
            <div class="image">
              <img src="../assets/SenseOfWorth3.png" alt />
            </div>
          </li>
          <li class="aaaa"     @mouseenter="onEnterT1"    @mouseleave="onLeaveTd1">
            <div class="title">
              <div>CRAFTSMAN</div>
              <div>SPIRIT</div>
              <div>工匠精神</div>
            </div>
            <div class="image">
              <img src="../assets/SenseOfWorth4.png" alt />
            </div>
          </li>
          <li class="">
            <div class="title2"     ref="title2">
              <div>LONG-</div>
              <div>TERMISM</div>
              <div>长期主义</div>
            </div>
            <div class="image">
              <img src="../assets/SenseOfWorth5.png" alt />
            </div>
          </li>
        </ul>-->
        <div class="containr">
          <!-- 第二版 变大 -->
          <div class="item">
            <img src="../assets/aboutSide1.png" alt />
          </div>
          <div class="item">
            <img src="../assets/aboutSide2.png" alt />
          </div>
          <div class="item">
            <img src="../assets/aboutSide3.png" alt />
          </div>
          <div class="item">
            <img src="../assets/aboutSide4.png" alt />
          </div>
          <div class="item">
            <img src="../assets/aboutSide5.png" alt />
          </div>

          <!-- 第二版本 hover高亮 -->
          <!--        
          <div class="item2">
            <div class="hover" id="hover1" ></div>
            <img src="../assets/aboutSide_2_1.png" alt />
            <div class="eng1" id="eng1">KEEP IN</div>
            <div class="eng2" id="eng2">SYNC</div>
            <div class="china" id="china1">始终同步 更进一步</div>
          </div>
          <div class="item2" @mousemove="hideHover1" @mouseleave="showHover1">
            <div class="hover"></div>
            <img src="../assets/aboutSide_2_2.png" alt />
            
            <div class="eng1">TEAMWORK IS</div>
            <div class="eng2">IMMINENT</div>
            <div class="china">群策群力 一触即发</div>
          </div>
          <div class="item2" @mousemove="hideHover1" @mouseleave="showHover1">
            <div class="hover"></div>
            <img src="../assets/aboutSide_2_3.png" alt />
            <div class="eng1">LONG-</div>
            <div class="eng2">TERMISM</div>
            <div class="china">长期主义</div>
          </div>
          <div class="item2" @mousemove="hideHover1" @mouseleave="showHover1">
            <div class="hover"></div>
            <img src="../assets/aboutSide_2_4.png" alt />
            <div class="eng1">CRAFTSMAN</div>
            <div class="eng2">SPIRIT</div>
            <div class="china">工匠精神</div>
          </div>
          <div class="item2" @mousemove="hideHover1" @mouseleave="showHover1">
            <div class="hover"></div>
            <img src="../assets/aboutSide_2_5.png" alt />
            <div class="eng1">CREATE INFINITE</div>
            <div class="eng2">POSSIBILITES</div>
            <div class="china">创造无限可能</div>
          </div>-->
        </div>
      </div>

      <!-- <div class="box">
  <ul>
        <li>
          <img src="../assets/SenseOfWorth5.png" alt />
        </li>
        <li>
          <img src="../assets/SenseOfWorth5.png" alt />
        </li>
        <li>
          <img src="../assets/SenseOfWorth5.png" alt />
        </li>
        <li>
          <img src="../assets/SenseOfWorth5.png" alt />
        </li>
      
        <li>
          <img src="../assets/SenseOfWorth5.png" alt />
        </li>
      

      </ul>
      </div>-->

      <!-- <div class="aboutSidextitle">
        <div class="aboutSidexfont1">6大专业团队</div>
        <div class="professionalteams">
          <div class="professional">
            <div class="professionalPic1"></div>
            <div class="professionaltitle">咨询</div>
            <div class="professionalfont">顶层规划</div>
          </div>
          <div class="professional">
            <div class="professionalPic2"></div>
            <div class="professionaltitle">空间</div>
            <div class="professionalfont">创意设计</div>
          </div>
          <div class="professional">
            <div class="professionalPic3"></div>
            <div class="professionaltitle">产品</div>
            <div class="professionalfont">解构分类</div>
          </div>
          <div class="professional">
            <div class="professionalPic4"></div>
            <div class="professionaltitle">交互</div>
            <div class="professionalfont">五感体验</div>
          </div>
          <div class="professional">
            <div class="professionalPic5"></div>
            <div class="professionaltitle">研发</div>
            <div class="professionalfont">前沿探索</div>
          </div>
          <div class="professional" style=" border-right: 1px solid transparent;">
            <div class="professionalPic6"></div>
            <div class="professionaltitle">运营</div>
            <div class="professionalfont">多维交付</div>
          </div>
        </div>
      </div>

      <div class="aboutSidextitle">
        <div class="aboutSidexfont1" style="background-color:#f0f0f0;padding-top: 85px;">企业资质</div>
        <div style="background-color:#f0f0f0">
          <div class="EnterpriseQualification">
            <div class="Enterpriseleft">
              <div class="Enterprisefont">
                建筑装修装饰工程专业承包
                <span class="span1">壹级资质</span>
              </div>
              <div class="Enterprisefont">
                建筑装饰工程设计专项
                <span class="span2">乙级资质</span>
              </div>
              <div class="Enterprisefont">
                展览陈列工程设计与施工一体化
                <span class="span3">壹级资质</span>
              </div>
              <div class="Enterprisefont">
                电子与智能化工程专业承包
                <span class="span4">壹级资质</span>
              </div>
              <div class="Enterprisefont">ISO9001：2015 质量管理体系认证</div>
              <div class="Enterprisefont">ISO14001：2015 环境管理体系认证</div>
              <div class="Enterprisefont" style="marign-bottom:0px">ISO45001：2018 职业健康管理体系认证</div>
            </div>
            <div class="Enterprisecenter">
              <div class="Enterprisefont">AAA资信等级证书</div>
              <div class="Enterprisefont">CMMI3级认证</div>
              <div class="Enterprisefont">国家高新技术企业</div>

              <div class="Enterprisefont">华为技术认证书</div>
              <div class="Enterprisefont">华为优选合作伙伴</div>
              <div class="Enterprisefont">华为云精英服务商</div>
              <div class="Enterprisefont" style="marign-bottom:0px">华为云解决方案伙伴</div>
            </div>
            <div class="Enterpriseright">
              <div class="Enterprisefont">知识产权贯标认证</div>
              <div class="Enterprisefont">重合同守信用企业证书</div>
              <div class="Enterprisefont">中国展览馆协会会员单位</div>
              <div class="Enterprisefont">中国电子商会数字展示专业委员会会员单位</div>
              <div class="Enterprisefont">江苏省装饰装修行业协会会员单位</div>
              <div class="Enterprisefont">江苏省通讯行业协会会员单位</div>
            </div>
          </div>
        </div>
      </div> -->

      <div class="qyzz">
        <img src="../assets/qyzz2.png" alt="">
      </div>

      <div class="aboutSidextitle">
        <div class="aboutSidexfont233">服务品牌</div>
        <div class="ServiceBrand">
          <img src="../assets/ServiceBrand.png" alt />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
export default {
  mounted() {
    // 监听页面滚动事件
    // window.addEventListener("scroll", this.scrolling);
    console.log("123123123");
  },
  beforeDestroy() {
    // window.removeEventListener("scroll", this.scrolling);
  },
  data() {
    return {
      tabTitle: ["策略咨询", "资源整合", "项目交付", "智能技术", "业务包装"],
      cur: 0, //默认选中第一个tab
      SenseOfWorth1: true,
      SenseOfWorth2: false,
      SenseOfWorth3: false,
      SenseOfWorth4: false,
      SenseOfWorth5: false
    };
  },
  mounted() {
    setTimeout(() => {}, 1000);
  },
  methods: {
    scrolling() {
      // 滚动条距文档顶部的距离
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // 滚动条滚动的距离
      let scrollStep = scrollTop - this.oldScrollTop;
      console.log("header 滚动距离 ", scrollTop);
      // 更新——滚动前，滚动条距文档顶部的距离
      this.oldScrollTop = scrollTop;

      //变量windowHeight是可视区的高度
      let windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      //变量scrollHeight是滚动条的总高度
      let scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;

      //滚动条到底部的条件
      if (scrollTop + windowHeight == scrollHeight) {
        //你想做的事情
        console.log("header  你已经到底部了");
      }
      if (scrollStep < 0) {
        console.log("header 滚动条向上滚动了！");
      } else {
        console.log("header  滚动条向下滚动了！");
      }
      // 判断是否到了最顶部
      if (scrollTop <= 0) {
        console.log("header 到了最顶部");
      }
    },
    hideHover1() {
      let hover1 = document.getElementById("hover1");
      let eng1 = document.getElementById("eng1");
      let eng2 = document.getElementById("eng2");
      let china = document.getElementById("china1");
      hover1.style.opacity = 1;
      eng1.style.opacity = 0;
      eng2.style.opacity = 0;
      china.style.opacity = 0;
    },
    showHover1() {
      let hover1 = document.getElementById("hover1");
      let eng1 = document.getElementById("eng1");
      let eng2 = document.getElementById("eng2");
      let china = document.getElementById("china1");
      hover1.style.opacity = 0;
      eng1.style.opacity = 1;
      eng2.style.opacity = 1;
      china.style.opacity = 1;
    },
    home() {
      this.$router.push("/home");
    },
    IndustryScenario() {
      this.$router.push("/IndustryScenario");
    },
    Solution() {
      this.$router.push("/Solution");
    },
    Servicecapability() {
      this.$router.push("/Servicecapability");
    },
    aboutSidex() {
      this.$router.push("/aboutSidex");
    },
    Selectedcases() {
      this.$router.push("/Selectedcases");
    },
    joinour() {
      this.$router.push("/JoinUS");
    },
    onEnterT1() {
      this.$refs.title2.style.opacity = "0";
    },
    onLeaveTd1() {
      this.$refs.title2.style.opacity = "1";
    },
    onEnterT2() {
      this.SenseOfWorth1 = false;
      this.SenseOfWorth2 = true;
      this.$refs.SenseOfWorth1.style.zIndex = "8";
      this.$refs.SenseOfWorth2.style.zIndex = "99";
    },
    onLeaveTd2() {
      this.SenseOfWorth1 = true;
      this.SenseOfWorth2 = false;
      this.$refs.SenseOfWorth1.style.zIndex = "10";
      this.$refs.SenseOfWorth2.style.zIndex = "9";
    },
    onEnterT3() {
      this.SenseOfWorth1 = false;
      this.SenseOfWorth3 = true;
      this.$refs.SenseOfWorth1.style.zIndex = "8";
      this.$refs.SenseOfWorth3.style.zIndex = "99";
    },
    onLeaveTd3() {
      this.SenseOfWorth1 = true;
      this.SenseOfWorth3 = false;
      this.$refs.SenseOfWorth1.style.zIndex = "10";
      this.$refs.SenseOfWorth3.style.zIndex = "8";
    },

    onEnterT4() {
      this.SenseOfWorth1 = false;
      this.SenseOfWorth4 = true;
      this.$refs.SenseOfWorth1.style.zIndex = "0";
      this.$refs.SenseOfWorth2.style.zIndex = "1";
      this.$refs.SenseOfWorth3.style.zIndex = "2";
      this.$refs.SenseOfWorth4.style.zIndex = "99";
    },
    onLeaveTd4() {
      this.SenseOfWorth1 = true;
      this.SenseOfWorth4 = false;
      this.$refs.SenseOfWorth1.style.zIndex = "10";
      this.$refs.SenseOfWorth2.style.zIndex = "9";
      this.$refs.SenseOfWorth3.style.zIndex = "8";
      this.$refs.SenseOfWorth4.style.zIndex = "7";
    },
    onEnterT5() {
      this.SenseOfWorth1 = false;
      this.SenseOfWorth5 = true;
      this.$refs.SenseOfWorth1.style.zIndex = "0";
      this.$refs.SenseOfWorth2.style.zIndex = "1";
      this.$refs.SenseOfWorth3.style.zIndex = "2";
      this.$refs.SenseOfWorth3.style.zIndex = "3";
      this.$refs.SenseOfWorth5.style.zIndex = "99";
    },
    onLeaveTd5() {
      this.SenseOfWorth1 = true;
      this.SenseOfWorth5 = false;
      this.$refs.SenseOfWorth1.style.zIndex = "10";
      this.$refs.SenseOfWorth2.style.zIndex = "9";
      this.$refs.SenseOfWorth3.style.zIndex = "8";
      this.$refs.SenseOfWorth4.style.zIndex = "7";
      this.$refs.SenseOfWorth5.style.zIndex = "6";
    }
  }
};
</script>


<style scoped>
.top {
  width: 100%;
  background-color: #6461a6;
  height: 64px;
  position: fixed;
  z-index: 999;
}

.logo {
  width: 75px;
  height: 37px;
  background-image: url(../assets/logo.png);
  background-size: 100% 100%;
  margin-left: 70px;
  margin-top: 13.5px;
  float: left;
}

.ability {
  font-size: 14px;
  float: right;
  margin-right: 9px;
  line-height: 64px;
  display: flex;
}

.items {
  color: #fff;
  margin-right: 61px;
  margin-top: 13.5px;
}

/* END */

/* 菜单与鼠标移入 */
.menu {
  width: 100%;
  height: 45px;
  line-height: 45px;
  text-align: center;
  position: relative;
  overflow: hidden;
  font-size: 14px;
  font-family: "SC_Light";
}

.menu:hover {
  z-index: 999;
  cursor: pointer;
  overflow: visible;
  font-family: "SC_Bold";
}

/* END */

/* 下拉菜单与鼠标移入 */

.dropbox {
  border-top: 2px solid #fff;
}

.drop {
  background: #6461a6;
  text-align: center;
  width: 96px;
  height: 45px;
  line-height: 45px;
  overflow: hidden;
  font-size: 14px;
  border-bottom: 1px solid #eee;
  font-family: "SC_Light";
}

.drop1 {
  background: transparent;
  text-align: center;
  width: 96px;
  height: 45px;
  line-height: 45px;
  overflow: hidden;
  font-size: 14px;
}

.drop:hover {
  font-family: "SC_Bold";
  cursor: pointer;
}
</style>

<style scoped>
.picture {
  width: 100%;
  margin-top: 64px;
  height: 900px;
  background-image: url("../assets/images/home/Image底.png");
  background-size: 100% 100%;
}

.serviceBox {
  position: relative;
  top: 45%;
  transform: translateY(-45%);
}

.servicetitle {
  animation: mymove 3s;
  animation-delay: 0s;
  color: #fff;
  text-align: center;
  font-family: "SC_Bold";
  letter-spacing: 1px;
  margin-bottom: 8px;
  font-size: 45px;
}

.servicefont1 {
  color: #fff;
  text-align: center;
  font-size: 25px;
  margin-bottom: 40px;
  font-family: "SC_Light";
  animation: mymove 3s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
  opacity: 0;
}

.servicefont {
  color: #fff;
  text-align: center;
  font-size: 14px;
  margin-bottom: 12px;
  font-family: "SC_Light";
  animation: mymove 3s;
  animation-delay: 2s;
  animation-fill-mode: forwards;
  opacity: 0;
}

.aboutSidextitle1 {
  width: 100%;
}

.aboutSidexfont1 {
  padding-top: 100px;
  text-align: center;
  font-size: 28px;
  font-family: "SC_Medium";
}
.aboutSidexfont233 {
  padding-top: 100px;
  text-align: center;
  font-size: 28px;
  margin-bottom: 100px;
  font-family: "SC_Bold";
}

.aboutSidexfont2 {
  text-align: center;
  font-size: 48px;
  font-family: "SC_Medium";
  padding-top: 60px;
}

.accordion {
  width: 1169px;
  height: 699px;
  margin: 0 auto;
  overflow: hidden;
  /* box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.4); */
}
.accordion ul {
  width: 3000px;
}
.accordion li {
  display: block;
  width: 20px;
  height: 400px;
  overflow: hidden;
  position: relative;
  float: left;
  border-left: 1px solid #aaa;
  /* box-shadow: 0 0 25px 10px rgba(0,0,0,0.4); */
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
.image {
  height: 100%;
  width: 100%;
}
.accordion li .image img {
  /* display: block; */
  height: 100%;
  width: 100%;
}
.accordion ul:hover li {
  width: 20px;
}
.accordion ul li:hover {
  width: 240px;
}
.accordion .title {
  position: absolute;
  color: #fff;
  left: 0;
  bottom: 0;
  width: 640px;
  background-color: #000;
}
.SenseOfWorth {
  width: 1169px;
  height: 399px;
  margin: 0 auto;
  overflow: hidden;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.4);
}

.SenseOfWorth1 {
  width: 350px;
  height: 399px;
  display: inline-block;
  background-image: url(../assets/SenseOfWorth1.png);
  background-size: 100% 100%;
  z-index: 10;
  position: absolute;
  /* transition: all 0.4s; */
  left: 0;
}
.SenseOfWorth1:hover {
  width: 300px;
}
.SenseOfWorth2 {
  width: 350px;
  height: 399px;
  display: inline-block;
  background-image: url(../assets/SenseOfWorth2.png);
  background-size: 100% 100%;
  z-index: 9;
  position: absolute;
  left: 205px;
}
.SenseOfWorth2:hover {
  width: 300px;
}
.SenseOfWorth3 {
  width: 350px;
  height: 399px;
  display: inline-block;
  background-image: url(../assets/SenseOfWorth3.png);
  background-size: 100% 100%;
  z-index: 8;
  position: absolute;
  left: 410px;
}

.SenseOfWorth4 {
  width: 350px;
  height: 399px;
  display: inline-block;
  background-image: url(../assets/SenseOfWorth4.png);
  background-size: 100% 100%;
  z-index: 7;
  position: absolute;
  left: 615px;
}

.SenseOfWorth5 {
  width: 350px;
  height: 399px;
  display: inline-block;
  background-image: url(../assets/SenseOfWorth5.png);
  background-size: 100% 100%;
  z-index: 6;
  position: absolute;
  left: 820px;
}

.SenseOfWorthslog {
  font-size: 20px;
  font-family: "SC_Bold";
  position: absolute;
  bottom: 5%;
  left: 30%;
}

.professionalteams {
  width: 1320px;
  height: 143px;
  margin: 0 auto;
  margin-top: 60px;
  position: relative;
  color: #fff;
  display: flex;
  margin-bottom: 80px;
}

.professional {
  width: 220px;
  height: 100%;
  border-right: 0.1px solid #757575;
}

.professionalPic1 {
  width: 70px;
  height: 70px;
  background-image: url("../assets/icon1.png");
  background-size: 100% 100%;
  margin: 0 auto;
  margin-bottom: 2px;
  margin-top: 20px;
}

.professionalPic2 {
  width: 63.33px;
  height: 63.33px;
  background-image: url("../assets/icon2.png");
  background-size: 100% 100%;
  margin: 0 auto;
  margin-bottom: 2px;
  margin-top: 20px;
}

.professionalPic3 {
  width: 63.33px;
  height: 63.33px;
  background-image: url("../assets/icon3.png");
  background-size: 100% 100%;
  margin: 0 auto;
  margin-bottom: 2px;
  margin-top: 20px;
}

.professionalPic4 {
  width: 63.33px;
  height: 63.33px;
  background-image: url("../assets/icon4.png");
  background-size: 100% 100%;
  margin: 0 auto;
  margin-bottom: 2px;
  margin-top: 20px;
}

.professionalPic5 {
  width: 63.33px;
  height: 63.33px;
  background-image: url("../assets/icon5.png");
  background-size: 100% 100%;
  margin: 0 auto;
  margin-bottom: 2px;
  margin-top: 20px;
}

.professionalPic6 {
  width: 63.33px;
  height: 63.33px;
  background-image: url("../assets/icon6.png");
  background-size: 100% 100%;
  margin: 0 auto;
  margin-bottom: 2px;
  margin-top: 20px;
}

.professionaltitle {
  text-align: center;
  color: #000;
  font-family: "SC_Bold";
  font-size: 20px;
  margin-bottom: 5px;
}

.professionalfont {
  text-align: center;
  color: #848484;
  font-family: "SC_Light";
  font-size: 16px;
}

.EnterpriseQualification {
  width: 1169px;
  margin: 0 auto;
  position: relative;
  color: #000;
  background-size: 100% 100%;
  padding-top: 60px;
  display: flex;
  height: 350px;
  /* background-color: red; */
  padding-bottom: 40px;
}

.Enterpriseleft {
  width: 460px;
  border-right: 0.1px solid #757575;
  border-left: 0.1px solid #757575;
  padding-left: 2%;
  height: 80%;
}

.Enterprisecenter {
  width: 360px;

  /* text-align: center; */
}
.Enterprisecenter .Enterprisefont {
  padding-left: 10%;
}

.Enterpriseright {
  width: 400px;
  padding-left: 70px;
  border-left: 0.1px solid #757575;
  height: 230px;
}

.Enterprisefont {
  margin-bottom: 20px;
  font-size: 16px;
  font-family: "SC_Light";
}

.ServiceBrand {
  width: 1200px;
  height: 800px;
  margin: 0 auto;
  margin-top: 46px;
  margin-bottom: 80px;
  position: relative;
  /* color: #fff; */
  background-color: transparent;
  /* background-image: url(../assets/ServiceBrand.png);
  background-size: 100% 100%; */
}
.ServiceBrand img {
  width: 100%;
  /* height: 100%;  */
}
@keyframes mymove {
  0% {
    transform: translateY(340px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@-webkit-keyframes mymove {
  from {
    bottom: -430px;
  }
  to {
    bottom: 0px;
  }
}

.box {
  margin-top: 70px;

  height: 400px;
  padding-left: 10.5px;
}
.box .containr {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.box .containr .item {
  /* background-image: url("../assets/aboutSide1.png");
  background-size: 100% 100%; */
  height: 100%;
  width: 270px;
  transition: all 0.5s;
  margin-left: 5px;
}
.box .containr .item img {
  width: 100%;
  height: 100%;
}
.box .containr .item:hover {
  transform: scale(1.1);
  margin: 0 40px;
}

.box .containr .item2 {
  /* background-image: url("../assets/aboutSide_2_1.png"); */
  /* background-size: 100% 100%; */
  height: 100%;
  width: 270px;
  position: relative;
  transition: all 0.5s;
  /* margin-left: 5px; */
}
.box .containr .item2 img {
  width: 100%;
  height: 100%;
}

.box .containr .item2 .hover {
  position: absolute;
  background-image: url("../assets/aboutSide_2_hover.png");
  width: 100%;
  height: 100%;
  opacity: 0.7;
  transition: all 0.5s;
}
.box .containr .item2 .hover:hover {
  opacity: 0;
}
.box .containr .item2 .eng1 {
  color: #fff;
  font-size: 20px;
  font-family: "SC_Bold";
  position: absolute;
  bottom: 20%;
  left: 10%;
  opacity: 0;
  transition: 0.5s all;
}
.box .containr .item2:hover .eng2 {
  opacity: 1;
}
.box .containr .item2:hover .eng1 {
  opacity: 1;
}
.box .containr .item2:hover .china {
  opacity: 1;
}
.box .containr .item2 .eng2 {
  color: #fff;
  font-size: 20px;
  font-family: "SC_Bold";
  position: absolute;
  bottom: 14%;
  left: 10%;
  opacity: 0;
  transition: 0.5s all;
}
.box .containr .item2 .china {
  color: #fff;
  font-size: 20px;
  font-family: "SC_Bold";
  position: absolute;
  bottom: 7%;
  left: 10%;
  opacity: 0;
  transition: 0.5s all;
}

.box ul {
  width: 100%;
  height: 400px;
  /* overflow: hidden; */
  transition: all 0.3s;
  margin: 0 auto;
}
.box ul li .title {
  position: absolute;
  width: 100%;
  color: #fff;
  font-size: 20px;
  font-family: "SC_Bold";
  position: absolute;
  bottom: 5%;
  left: 30%;
  opacity: 0;
  transition: all 0.5s;
}
.box ul li .title2 {
  position: absolute;
  width: 100%;
  color: #fff;
  font-size: 20px;
  font-family: "SC_Bold";
  position: absolute;
  bottom: 5%;
  left: 30%;
  transition: all 0.5s;
  opacity: 1;
}

.box ul li img {
  height: 100%;
}
.box ul li {
  box-shadow: -10px 0px 10px rgb(0, 0, 0, 0.5);
  position: relative;
  float: left;
  list-style-type: none;
  width: 260px;
  height: 400px;
  transition: all 0.5s;
}
.box ul li:hover .title {
  opacity: 1;
  /* background-color: red; */
}

.box ul li:hover {
  width: 341px;
  box-shadow: -10px 0px 10px rgb(0, 0, 0, 0);
}
.box ul li:hover li.next {
  box-shadow: -10px 0px 10px rgb(0, 0, 0, 0);
}
.Enterprisefont .span1 {
  padding-left: 40px;
}
.Enterprisefont .span2 {
  padding-left: 72px;
}
.Enterprisefont .span3 {
  padding-left: 8px;
}
.Enterprisefont .span4 {
  padding-left: 40px;
}

/* 以下是旋转木马效果 */
#carousel {
  position: relative;
  width: 1200px;
  margin: 0 auto;
}

#carousel .swiper {
  padding-top: 20px;
  padding-bottom: 40px;
}
#carousel .swiper-wrapper {
}
#carousel .swiper-slide {
  width: 300px;
  background: #fff;
  box-shadow: 0 8px 20px #ddd;
}
#carousel .swiper-slide img {
  display: block;
  width: 100%;
}
#carousel .swiper-slide p {
  line-height: 58px;
  padding-top: 0;
  text-align: center;
  color: #636363;
  font-size: 0.8em;
  margin: 0;
}

#carousel .swiper-pagination {
  width: 100%;
  bottom: 20px;
}

#carousel .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 5px;
  background-color: #e8f5ff;
  width: 20px;
  height: 10px;
  opacity: 1;
  border-radius: 5px;
}

#carousel .swiper-pagination-bullets .swiper-pagination-bullet-active {
  background-color: #3eb2f3;
  width: 35px;
}

#carousel .swiper-button-prev {
  left: -30px;
  width: 45px;
  height: 45px;
  /* background: url(images/wm_button_icon.png) no-repeat; */
  background-position: 0 0;
  background-size: 100%;
}

#carousel .swiper-button-prev:hover {
  background-position: 0 -46px;
  background-size: 100%;
}

#carousel .swiper-button-next {
  right: -30px;
  width: 45px;
  height: 45px;
  /* background: url(images/wm_button_icon.png) no-repeat; */
  background-position: 0 -93px;
  background-size: 100%;
}

#carousel .swiper-button-next:hover {
  background-position: 0 -139px;
  background-size: 100%;
}
#carousel .swiper-button-prev::after,
#carousel .swiper-button-next::after {
  content: "";
}

.qyzz {
  width: 100%;
  height: 500px;
  margin-top: 100px;
}
.qyzz img {
  width: 100%;
  height: 100%;
}
#hover1 {
  opacity: 0;
}
#eng1 {
  opacity: 1;
}
#eng2 {
  opacity: 1;
}
#china1 {
  opacity: 1;
}
</style> 