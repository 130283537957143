<template>
  <div class="category">
    <div class="categoryTop">
      <img src="../../assets/images/category.png" class="jobBanner" />
      <div class="inputButton">
        <el-input placeholder="输入职位进行搜索" class="inputStyle" v-model="inp">
          <i slot="prefix" class="el-icon-search icon" style></i>
        </el-input>
        <button class="search" @click="toSearch">搜索</button>
      </div>
    </div>

    <div class="categoryBottom">
      <div class="categoryBottom_Left">
        <div class="positionCategory">职位类别</div>
        <el-tree
          :data="data"
          :props="defaultProps"
          show-checkbox
          @node-click="handleNodeClick"
          :filter-node-method="filterNode"
          ref="tree"
          @check-change="handleCheckChange"
        ></el-tree>

        <!-- <el-tree
          :data="data"
          show-checkbox
          node-key="id"
          :default-expanded-keys="[2, 3]"
          :default-checked-keys="[5]"
          :props="defaultProps"
        ></el-tree>-->
      </div>
      <div class="categoryBottom_Right">
        <div class="positionCategory_1">开启思德探索之旅</div>
        <div
          class="categoryBottom_Right_bottom"
          @click="ToDetails(item.id)"
          v-for="(item,k) in allJob"
          :key="k"
        >
          <div class="post">{{item.name}}</div>
          <div class="requirement">
            {{item.place}}
            <i>|</i>
            {{item.degree}}
            <i>|</i>
            {{item.year}}
            <i>|</i>
            职位ID：{{item.jobId}}
          </div>
          <div class="details">
            <span>{{item.duty}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "category",
  mounted() {
    //  全部数据
    this.$axios("getRecruit").then(res => {
      console.log(res);
      this.allJob = res.data.data;
    });

    //左边树状数据
    this.$axios("getRecruitType").then(res => {
      this.data = res.data.data;
      console.log("123123123123", this.data);
    });
  },
  data() {
    return {
      inp: "",
      allJob: [],
      data: [
        // {
        //     label: '市场&运营类',
        //     children: [{
        //         label: '二级 1-1',
        //         children: [{
        //             label: '三级 1-1-1'
        //         }]
        //     }]
        // }, {
        //     label: '质量管理类',
        //     children: [{
        //         label: '二级 2-1',
        //         children: []
        //     }, {
        //         label: '二级 2-2',
        //         children: []
        //     }]
        // }, {
        //     label: '策略&策划类',
        //     children: [{
        //         label: '二级 3-1',
        //         children: []
        //     }]
        // },
        // {
        //     label: '美术类',
        //     children: [{
        //         label: '二级 4-1',
        //         children: []
        //     }]
        // },
        // {
        //     label: '多媒体表现类',
        //     children: [{
        //         label: '二级 5-1',
        //         children: []
        //     }]
        // },
        // {
        //     label: '综合类',
        //     children: [{
        //         label: '二级 6-1',
        //         children: []
        //     }]
        // },
        // {
        //     label: '资源合作',
        //     children: [{
        //         label: '二级 7-1',
        //         children: []
        //     }]
        // }
      ],
      defaultProps: {
        children: "children",
        label: "title",
        isLeaf: (data, node) => {
          if (node.level === 2) {
            return true;
          }
        }
      },
      str: "getRecruitByJobArray"
    };
  },
  methods: {
    //左侧树状筛选代码
    filterNode(value, data, node) {
      console.log("value", value);
      console.log("data", data);
      console.log("node", node);
    },
    //点击搜索
    toSearch() {
      this.$axios(`getRecruit?name=${this.inp}`).then(res => {
        console.log(res);
        this.allJob = res.data.data;
      });
    },
    home() {
      this.$router.push("/home");
    },
    handleCheckChange(data, checked, indeterminate) {
      this.str = "getRecruitByJobArray";
      // console.log(data, checked, indeterminate);
      // console.log('??')
      // console.log('data:',data);
      // console.log('indeterminate:',indeterminate);
      // console.log('checked:',checked);

      let res = this.$refs.tree.getCheckedNodes();

      let arrDeptId = [];

      res.forEach(item => {
        arrDeptId.push(item.id);
      });
      console.log(arrDeptId);
      arrDeptId.forEach((value, index) => {
        if (index === 0) {
          this.str += "?jobIds=" + value;
        } else {
          this.str += "&jobIds=" + value;
        }
      });
      console.log("this.str", this.str);
      if (this.str == "getRecruitByJobArray") {
        this.str += "?jobIds=-1";
      }
      this.$axios.get(this.str).then(res => {
        console.log("hhe1", res);
        this.allJob = res.data.data;
      });
    },
    handleNodeClick(data) {
      // console.log(data);
    },
    ToDetails(id) {
      console.log("我送过去了:", id);
      this.$router.push({
        name: "categoryDetails",
        params: {
          id
        }
      });
    },
    IndustryScenario() {
      this.$router.push("/IndustryScenario");
    },
    Solution() {
      this.$router.push("/Solution");
    },
    Servicecapability() {
      this.$router.push("/Servicecapability");
    },
    aboutSidex() {
      this.$router.push("/aboutSidex");
    },
    Selectedcases() {
      this.$router.push("/Selectedcases");
    },
    joinour() {
      this.$router.push("/JoinUS");
    }
  }
};
</script>
<style scoped>
.top {
  width: 100%;
  background-color: #6461a6;
  height: 64px;
  position: fixed;
  z-index: 999;
}

.logo {
  width: 75px;
  height: 37px;
  background-image: url(../../assets/logo.png);
  background-size: 100% 100%;
  margin-left: 70px;
  margin-top: 13.5px;
  float: left;
}

.ability {
  font-size: 14px;
  float: right;
  margin-right: 9px;
  line-height: 64px;
  display: flex;
}

.items {
  color: #fff;
  margin-right: 61px;
  margin-top: 13.5px;
}

/* END */

/* 菜单与鼠标移入 */
.menu {
  width: 100%;
  height: 45px;
  line-height: 45px;
  text-align: center;
  position: relative;
  overflow: hidden;
  font-size: 14px;
  font-family: "SC_Light";
}

.menu:hover {
  z-index: 999;
  cursor: pointer;
  overflow: visible;
  font-family: "SC_Bold";
}

/* END */

/* 下拉菜单与鼠标移入 */

.dropbox {
  border-top: 2px solid #fff;
}

.drop {
  background: #6461a6;
  text-align: center;
  width: 96px;
  height: 45px;
  line-height: 45px;
  overflow: hidden;
  font-size: 14px;
  border-bottom: 1px solid #eee;
  font-family: "SC_Light";
}

.drop1 {
  background: transparent;
  text-align: center;
  width: 96px;
  height: 45px;
  line-height: 45px;
  overflow: hidden;
  font-size: 14px;
}

.drop:hover {
  font-family: "SC_Bold";
  cursor: pointer;
}
</style>
<style scoped>
.category {
  width: 100%;
  padding-bottom: 80px;
  overflow: hidden;
}

.categoryTop {
  width: 100%;
  margin-bottom: 100px;
  position: relative;
}

.jobBanner {
  margin-top: 64px;
  width: 100%;
}

.inputButton {
  width: 700px;
  height: 71px;
  position: absolute;
  bottom: -25px;
  left: 50%;
  margin-left: -350px;
}

.el-input {
  width: 533px;
}

.inputStyle >>> input {
  height: 70px;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  text-indent: 45px;
  font-size: 16px;
  border: none;
  box-shadow: -10px 30px 30px -5px #f2f2f2;
}

.el-input >>> .el-input__prefix {
  left: 35px !important;
  font-size: 16px;
}

.search {
  width: 167px;
  height: 70px;
  color: #fff;
  background: #6461a6;
  border: none;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  font-size: 18px;
  font-family: "SC_Bold";
  box-shadow: 5px 30px 30px -5px #f2f2f2;
}

.categoryBottom {
  width: 1280px;
  overflow: hidden;
  margin: 0 auto;
}

.categoryBottom_Left {
  width: 246px;
  float: left;
  text-align: left;
  padding-top: 20px;
  padding-bottom: 28px;
  border-bottom: 1px solid #f2f2f2;
  padding-left: 50px;
}

.positionCategory {
  padding-bottom: 15px;
  font-size: 22px;
  font-family: "SC_Medium";
  color: #000000;
}

.el-tree >>> .el-tree-node__label {
  font-size: 16px;
  font-family: "SC_Light";
}

.el-tree >>> .el-tree-node__expand-icon {
  font-size: 15px;
  color: #000000;
}

.el-tree >>> .el-tree-node__content {
  padding-bottom: 15px;
}

.el-tree >>> .el-tree-node__content > label.el-checkbox {
  margin-right: 15px;
}

.el-tree >>> .el-checkbox__inner {
  width: 19px;
  height: 19px;
}

.categoryBottom_Right {
  float: right;
  width: 890px;
  border-left: 1px solid #f2f2f2;
  padding-left: 60px;
}

.positionCategory_1 {
  font-size: 30px;
  font-family: "SC_Black";
  padding-top: 20px;
  padding-bottom: 40px;
  text-align: left;
}

.categoryBottom_Right_bottom {
  width: 800px;
  height: 178px;
  padding-bottom: 20px;
  text-align: left;
  font-family: "SC_Medium";
  margin-bottom: 30px;
}

.categoryBottom_Right_bottom:hover {
  box-shadow: 16px 19px 17px -7px #f2f2f2;
  cursor: pointer;
}

.post {
  font-size: 22px;
  color: #333333;
  line-height: 35px;
  padding-left: 20px;
  padding-top: 15px;
}

.requirement {
  font-size: 16px;
  color: #333333;
  opacity: 0.6;
  line-height: 45px;
  padding-left: 20px;
}

.requirement i,
.post i {
  font-style: normal;
  padding-left: 5px;
  padding-right: 5px;
  display: inline-block;
}

.details {
  width: 653px;
  font-size: 14px;
  color: #333333;
  opacity: 0.4;
  line-height: 25px;
  height: 80px;
  padding-left: 20px;
  overflow: hidden;

  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
}

.details span {
  display: block;
  white-space: pre-line;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  height: 90%;
  width: 100%;
}
.icon {
  height: 100%;
  width: 25px;
  text-align: center;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  line-height: 70px;
}


.el-tree>>>.is-leaf::before {
    display: none;
  }
  .el-tree>>>.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner{
    background-color: #6461a6 !important;

  }
.el-tree>>>.el-checkbox__input.is-checked .el-checkbox__inner, .el-tree>>>.el-checkbox__input.is-indeterminate .el-checkbox__inner{
  background-color: #6461a6 !important;

}
.el-checkbox__inner{
  background-color: #6461a6 !important;
}

.el-tree>>>.el-checkbox__inner::after{
  top: 4px;
  left: 6px;
  
}
.el-tree>>>.el-tree-node__children .el-tree-node__label{
    font-size: 100px !important;
    font-weight: normal !important;
    /* color: red !important; */
}
.el-tree>>>.el-tree-node .el-tree-node__content .el-tree-node__label {
  font-size: 15px !important;
  font-weight: bold;

}

</style>