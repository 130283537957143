<template>
  <div style="overflow:hidden">
    <topBanner></topBanner>
    <toTop @show='ishow'></toTop>
    <Popup></Popup>
    <contentUs></contentUs>
   
    <router-view />
 
    <Bottom></Bottom>
  </div>
</template>

<script>
import topBanner from "@/components/topBanner.vue";
import toTop from "./components/toTop.vue";
import Bottom from "@/components/bottom.vue";
import Popup from "@/components/Popup/Popup.vue";
import contentUs from "@/components/Popup/contentUs.vue";
export default {
  components: {
    toTop,
    topBanner,
    Bottom,
    Popup,
    contentUs
  },
  mounted () {
    //为后面的H5做准备,直接跳转过去
    // if (this._isMobile()) {
    //   alert("手机端");
  
    // } else {
    //   alert("pc端");
   
    // }

  },
  methods: {
    _isMobile() {
	 let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
	 return flag;
},

    ishow(item) {
      console.log(item)
    }
  },
  data() {
    return {
      ishow23:false
    };
  }
};
</script>

<style scoped>
*{
  user-select:none
}
video {
    object-fit: cover;
}
</style>