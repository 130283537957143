<template>
  <div class="body">
    <div class="center">
      <div class="Selectedcasespic"></div>
      <div class="Selectedcasestitle">解决方案</div>

      <div class="SolutionBox">
          <div
            v-for="(item,k) in jumpToSolution"
            :key="k"
            class="solutioncenter"
            :id="`item_block_${k+1}`"
            @click="jumpToSoluitionChild(item.id)"
          >
            <div class="solutionpic">
              <img :src="item.url" alt />
            </div>
            <div class="solutionIntroduce">{{item.smallTitle}}</div>
            <div class="solutionName">{{item.subTitle}}</div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
export default {
  data() {
    return {
      jumpToSolution: []
    };
  },
  mounted() {
    this.$axios.get("getSolutionAll").then(res => {
      res.data.data.forEach(value => {
        const val = JSON.parse(value.subTitle);
        value.smallTitle = val.smallTitle;
        value.subTitle = val.subTitle;
      });

      this.jumpToSolution = res.data.data;

      this.$nextTick(() => {
        gsap.from("#item_block_1", {
          scrollTrigger: "#item_block_1",
          duration: 2,
          opacity: 0,
          y: 200
        });
        gsap.from("#item_block_2", {
          scrollTrigger: "#item_block_2",
          duration: 1,
          opacity: 0,
          y: 200
        });
        gsap.from("#item_block_3", {
          scrollTrigger: "#item_block_3",
          duration: 2.2,
          opacity: 0,
          y: 200
        });
        gsap.from("#item_block_4", {
          scrollTrigger: "#item_block_4",
          duration: 1,
          opacity: 0,
          y: 200
        });

        gsap.from("#item_block_5", {
          scrollTrigger: "#item_block_5",
          duration: 2,
          opacity: 0,
          y: 200
        });
        gsap.from("#item_block_6", {
          scrollTrigger: "#item_block_6",
          duration: 1,
          opacity: 0,
          y: 200
        });
        gsap.from("#item_block_7", {
          scrollTrigger: "#item_block_7",
          duration: 2.2,
          opacity: 0,
          y: 200
        });
        gsap.from("#item_block_8", {
          scrollTrigger: "#item_block_8",
          duration: 1,
          opacity: 0,
          y: 200
        });
        gsap.from("#item_block_9", {
          scrollTrigger: "#item_block_9",
          duration: 2,
          opacity: 0,
          y: 200
        });
        gsap.from("#item_block_10", {
          scrollTrigger: "#item_block_10",
          duration: 1,
          opacity: 0,
          y: 200
        });
        gsap.from("#item_block_11", {
          scrollTrigger: "#item_block_11",
          duration: 2.2,
          opacity: 0,
          y: 200
        });
        gsap.from("#item_block_12", {
          scrollTrigger: "#item_block_12",
          duration: 1,
          opacity: 0,
          y: 200
        });
        gsap.from("#item_block_13", {
          scrollTrigger: "#item_block_13",
          duration: 1,
          opacity: 0,
          y: 200
        });
        gsap.from("#item_block_14", {
          scrollTrigger: "#item_block_14",
          duration: 2,
          opacity: 0,
          y: 200
        });
        gsap.from("#item_block_15", {
          scrollTrigger: "#item_block_15",
          duration: 1,
          opacity: 0,
          y: 200
        });
        gsap.from("#item_block_16", {
          scrollTrigger: "#item_block_16",
          duration: 2.2,
          opacity: 0,
          y: 200
        });
        gsap.from("#item_block_17", {
          scrollTrigger: "#item_block_17",
          duration: 2,
          opacity: 0,
          y: 200
        });
        gsap.from("#item_block_18", {
          scrollTrigger: "#item_block_18",
          duration: 1,
          opacity: 0,
          y: 200
        });
      });
    });
  },
  methods: {
    jumpToSoluitionChild(id) {
      let hycj=document.getElementById('hycj')
      let jjfa=document.getElementById('jjfa')
      let jxal=document.getElementById('jxal')
      let fwnl=document.getElementById('fwnl')
      let gysd=document.getElementById('gysd')
      let jrwm=document.getElementById('jrwm')
      hycj.style.fontFamily='SC_Light'
      jjfa.style.fontFamily='SC_Bold'
      jxal.style.fontFamily='SC_Light'
      fwnl.style.fontFamily='SC_Light'
      gysd.style.fontFamily='SC_Light'
      jrwm.style.fontFamily='SC_Light'
      if (this.$route.path !== "/Solution") {
        this.$router.push({name:"Solution",params:{id}});
      }
   
    },
    gocase(id) {
      this.$router.push({
        name: "case",
        params: {
          id
        }
      });
    }
  }
};
</script>
 
<style scoped>
.SolutionBox {
  width: 1830px;
  /* height: 1490px; */
  margin: 0 45px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
  background-color: #f7f7f7;
  margin-bottom: 100px;
}
.solutioncenter {
  width: 448px;
  height: 430px;
  padding-bottom: 40px;
  margin-bottom: 40px;
  background-color: #fff;
  /* transition: all 0.5s; */
  /* transform: translateY(120px); */
}

.solutionpic {
  width: 100%;
  height: 90%;
}
.solutionpic img {
  width: 100%;
  height: 100%;
  margin-bottom: 12px;
}
.solutionIntroduce {
  font-size: 20px;
  line-height: 20px;
  color: #333333;
  font-family: "SC_Medium";
  margin-bottom: 10px;
  padding-left: 10px;
  margin-top: 20px;
  white-space: pre;
}

.solutionName {
  font-size: 14px;
  color: #333333;
  padding-left: 10px;
  line-height: 14px;
  white-space: pre;
}

.top {
  width: 100%;
  background-color: #6461a6;
  height: 64px;
  position: fixed;
  z-index: 999;
}

.logo {
  width: 75px;
  height: 37px;
  background-image: url(../assets/logo.png);
  background-size: 100% 100%;
  margin-left: 70px;
  margin-top: 13.5px;
  float: left;
}

.ability {
  font-size: 14px;
  float: right;
  margin-right: 9px;
  line-height: 64px;
  display: flex;
}

.items {
  color: #fff;
  margin-right: 61px;
  margin-top: 13.5px;
}

/* END */

/* 菜单与鼠标移入 */
.menu {
  width: 100%;
  height: 45px;
  line-height: 45px;
  text-align: center;
  position: relative;
  overflow: hidden;
  font-size: 14px;
  font-family: "SC_Light";
}

.menu:hover {
  z-index: 999;
  cursor: pointer;
  overflow: visible;
  font-family: "SC_Bold";
}

/* END */

/* 下拉菜单与鼠标移入 */

.dropbox {
  border-top: 2px solid #fff;
}

.drop {
  background: #6461a6;
  text-align: center;
  width: 96px;
  height: 45px;
  line-height: 45px;
  overflow: hidden;
  font-size: 14px;
  border-bottom: 1px solid #eee;
  font-family: "SC_Light";
}

.drop1 {
  background: transparent;
  text-align: center;
  width: 96px;
  height: 45px;
  line-height: 45px;
  overflow: hidden;
  font-size: 14px;
}

.drop:hover {
  font-family: "SC_Bold";
  cursor: pointer;
}
</style>

<style scoped>
.Selectedcasespic {
  width: 100%;
  margin-top: 64px;
  height: 900px;
  background-image: url("../assets/Selectedcases.png");
  background-size: 100% 100%;
  /* margin-bottom: 100px; */
}

.Selectedcasestitle {
  text-align: center;
  color: #333333;
  background-color: #f7f7f7;
  font-size: 35px;
  font-family:"SC_Bold";
  padding-bottom: 60px;
  padding-top: 100px;
}

.Selectedcasesfont {
  text-align: center;
  color: #333333;
  font-size: 18px;
  margin-bottom: 6px;
  margin-bottom: 80px;
}

.selectedCasesBox {
  width: 1830px; /*1804px; */
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.selectedCasescenter {
  width: 601.333px;
  overflow: hidden;
  /* height: 9.375px; */
  background-color: #fff;

  /* margin-top: 20px; */
  /* min-height:7.375px;
  max-height: 9.375px; */
  transition: all 0.4s;
  margin-bottom: 40px;
  /* border: 2px solid black; */
  height: 450.333px;
}
.selectedCasespic img {
  width: 100%;
  height: 100%;
}
.selectedCasescenter:hover {
  box-shadow: 0 8px 8px 0 #f2f2f2;
  /* transform: translate(0, -10px);  */
}
.selectedCasesIntroduce {
  font-size: 20px;
  color: #333333;
  font-family: "SC_Medium";
  margin-bottom: 10px;
  margin-top: 10px;
  padding-left: 10px;
}

.selectedCasesName {
  font-size: 14px;
  padding-left: 10px;
  color: #333333;
}
</style>